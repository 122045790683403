<div class="banner mat-elevation-z4">
  <img
    class="logo"
    src="{{ deviceType?.brand?.logoUrl }}"
    alt="Data source logo"
  />
  <h3 class="title">
    {{ deviceType?.brand?.name }} {{ deviceType?.metadata?.label }}
  </h3>
</div>
