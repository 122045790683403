import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, TemplateRef } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, of, pipe } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { DeviceManagementService } from 'src/app/pages/main/device-management/device-management.service';
import { DevicesService } from 'src/app/services/http/devices.service';
import { InterfaceStateService } from 'src/app/services/state/interface/interface-store.service';
import { PubSubMessage } from 'src/models/data-routing.models';
import { DeviceTypeDto } from 'src/models/device-type.models';
import { DeviceDto } from 'src/models/device.models';
import { DataStoreService } from '../../../../services/state/data/data-store.service';
import { DevicePictureUpdateComponent } from '../device-picture-update/device-picture-update.component';
import { SendDownlinkDialogComponent } from '../send-downlink-dialog/send-downlink-dialog.component';
import { DeviceUpdateComponent } from './../device-update/device-update.component';
import { DeviceCalibrationFunctionComponent } from './device-calibration-function/device-calibration-function.component';

@Component({
  selector: 'app-device-detail',
  templateUrl: './device-detail.component.html',
  styleUrls: ['./device-detail.component.scss'],
  animations: [
    trigger('json', [
      state('void', style({ width: '0' })),
      state('*', style({ width: '*' })),
      transition(':enter', animate('250ms ease-out')),
      transition(':leave', animate('250ms ease-out')),
    ]),
  ],
})
export class DeviceDetailComponent {
  device: DeviceDto;
  deviceList: DeviceDto[];
  messages?: PubSubMessage[];
  messageKeys: string[] = [];
  openWarnings: string[];

  deviceMessagesCount = 0;
  pageIndex = 0;
  pageSize = 25;
  messagesLoading = false;
  selectedRow?: PubSubMessage;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private deviceHttp: DevicesService,
    private deviceManagement: DeviceManagementService,
    private interfaceState: InterfaceStateService,
    private snackBar: MatSnackBar,
    private data: DataStoreService,
  ) {
    this._loadData();
  }

  private _loadData() {
    this.data.devices$
      .pipe(
        switchMap((devices) => {
          const device = devices?.find(
            (dev) => dev.device_id === this.route.snapshot.params.id,
          );
          return device
            ? of(device)
            : this.deviceHttp.getDeviceById(this.route.snapshot.params.id);
        }),
        tap((device) => {
          this.device = device;
          this.deviceList = [device];
          this.openWarnings = device.attribute_tags.map(
            (tag) => tag.metadata.document_marking,
          );
        }),
        switchMap((device) => of(device).pipe(this.setMessagesAndKeys$)),
        first(),
      )
      .subscribe();
  }

  private get setMessagesAndKeys$() {
    return pipe(
      switchMap((device: DeviceDto) =>
        combineLatest([
          this.deviceHttp
            .getDeviceTypeByID(device.device_type.device_type_id, true)
            .pipe(this.extractBusinessFields$()),
          this.deviceHttp
            .getDeviceMessages(device, undefined, undefined, this.pageSize)
            .pipe(
              tap((msg) => (this.messages = msg)),
              this.extractMessageKey$(),
            ),
          this.deviceHttp
            .getDeviceMessagesCount(device)
            .pipe(tap((count) => (this.deviceMessagesCount = count))),
        ]),
      ),
      map(([businessFields, messageFields]) => [
        '_received_timestamp',
        ...(businessFields.length ? businessFields : messageFields),
      ]),
      tap((keys) => {
        if (this.messageKeys.length === 0) {
          this.messageKeys = keys;
        }
      }),
    );
  }

  updateMessages({ pageSize, pageIndex }: PageEvent) {
    const msg = this.messages!;
    let pageAction: 'PREV' | 'NEXT';
    let referenceTimestamp: number;

    if (pageIndex > this.pageIndex) {
      pageAction = 'NEXT';
      referenceTimestamp = msg[msg.length - 1]._received_timestamp;
    } else {
      pageAction = 'PREV';
      referenceTimestamp = msg[0]._received_timestamp;
    }

    this.messagesLoading = true;

    this.pageIndex = pageIndex;

    this.deviceHttp
      .getDeviceMessages(
        this.device,
        undefined,
        undefined,
        pageSize,
        pageAction,
        referenceTimestamp,
      )
      .pipe(
        tap((msg) => {
          this.messages = msg;
          this.messagesLoading = false;
        }),
        first(),
      )
      .subscribe();
  }

  private extractBusinessFields$() {
    return pipe(
      map((deviceType: DeviceTypeDto) =>
        deviceType.exposed_data
          ? deviceType.exposed_data.business_fields.map((field) =>
              field.output_name ? field.output_name : field.name,
            )
          : [],
      ),
    );
  }

  private extractMessageKey$() {
    const reducer = (acc: string[], curr: PubSubMessage) =>
      acc.concat(this.getSortedObjectKeys(curr));
    return pipe(
      map((messages: PubSubMessage[]) => messages.reduce(reducer, [])),
      map((fields) => Array.from(new Set(fields))),
    );
  }

  public getSortedObjectKeys(obj: object): string[] {
    return Object.keys(obj)
      .filter((key) => !key.startsWith('_'))
      .sort((a: string, b: string) => a.localeCompare(b));
  }

  public onEdit() {
    const dialogRef = this.dialog.open(DeviceUpdateComponent, {
      width: '700px',
      data: { device: this.device },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.device = res;
        this.openWarnings = res.attribute_tags.map(
          (tag) => tag.metadata.document_marking,
        );
        this.deviceList = [res];
      }
    });
  }

  public onDelete(templateRef: TemplateRef<unknown>) {
    const deleteMsg = `Device ${this.device.device_id} was successfully deleted!`;
    const dialogRef = this.dialog.open(templateRef);

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((res) =>
          res
            ? this.deviceManagement.deleteDevice(this.device).pipe(
                tap(async () => {
                  await this.onClose();
                  this.snackBar.open(deleteMsg, 'close');
                }),
              )
            : of(null),
        ),
      )
      .subscribe();
  }

  public onSendDownlink() {
    this.dialog.open(SendDownlinkDialogComponent, {
      data: {
        deviceId: this.device.device_id,
        sourceId: this.device.source_id,
      },
    });
  }

  public onClose() {
    this.router.navigateByUrl(this.interfaceState.lastRoutedUrl);
  }

  public get actionsHidden(): boolean {
    const source = this.device.source_id;
    return (
      source === 'pub_sub' ||
      source === 'sigfox' ||
      !this.deviceManagement.userHasWriteRights(this.device.tags[0].tag_id)
    );
  }

  public onEditPicture(imageUrl) {
    const dialogRef = this.dialog.open(DevicePictureUpdateComponent, {
      width: '700px',
      data: { device: this.device, imageUrl },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const info = { ...this.device, ...res };
        this.device = info;
        this.deviceList = [info];
      }
    });
  }

  public onEnlargePicture(templateRef: TemplateRef<unknown>) {
    this.dialog.open(templateRef, { width: '800px', height: '800px' });
  }

  get deviceImageUrl() {
    const imgUrl =
      this.device.metadata.device_logo_url ||
      this.device.device_type.metadata.device_logo_url ||
      './assets/img/no_image_available.png';
    return `url('${imgUrl}')`;
  }

  onFunction() {
    const ref = this.dialog.open(DeviceCalibrationFunctionComponent, {
      width: '80vw',
      data: { fields: this.messageKeys, device: this.device },
    });

    ref.componentInstance.deviceUpdated.subscribe(
      (device) =>
        (this.device.calibration_functions = device.calibration_functions),
    );
    ref.backdropClick().subscribe(() => ref.close());

    ref
      .afterClosed()
      .pipe(filter((v) => !!v))
      .subscribe(() =>
        this.snackBar.open('Device characterized successfully', 'OK'),
      );
  }

  closeWarning(index: number) {
    this.openWarnings.splice(index, 1);
  }

  canSendDownlink(): boolean {
    return (
      this.device.source_id === 'firefly' ||
      this.device.source_id === 'iot_core'
    );
  }
}
