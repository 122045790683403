import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { merge } from 'rxjs';
import { filter, startWith, tap } from 'rxjs/operators';

import { AuthService } from 'src/app/services/state/auth/auth.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { Brand, DeviceTypeDto, Source } from 'src/models/device-type.models';

@Component({
  selector: 'app-model-filter',
  templateUrl: './model-filter.component.html',
  styleUrls: ['./model-filter.component.scss'],
})
export class ModelFilterComponent implements OnInit {
  @Input() models: DeviceTypeDto[];
  @Input() manufacturers: Brand[];
  @Input() sources: Source[];
  @Input() tags: string[];
  @Input() showViewOption = true;
  @Output() modelsFiltered = new EventEmitter<DeviceTypeDto[]>();
  @Output() tileViewActive = new EventEmitter<boolean>();
  form: UntypedFormGroup;

  constructor(
    private data: DataStoreService,
    public auth: AuthService,
  ) {}

  ngOnInit(): void {
    this.form = this.buildFilterForm();

    merge(
      this.filterForm.valueChanges,
      this.data.deviceTypes$.pipe(
        filter((models) => !!models?.length),
        tap((models) => (this.models = models ? models : [])),
      ),
    )
      .pipe(
        startWith(this.filterForm.value),
        tap(() => {
          this.emitFilteredModels();
        }),
      )
      .subscribe();

    this.form.controls.tileView.valueChanges
      .pipe(
        startWith(this.form.controls.tileView.value),
        tap((val) => this.tileViewActive.emit(val)),
      )
      .subscribe();
  }

  private emitFilteredModels() {
    let result = this.models;
    result = this.brandFilter(result);
    result = this.sourceFilter(result);
    result = this.dataTagFilter(result);
    result = this.keywordFilter(result);
    result = this.disabledFilter(result);
    this.modelsFiltered.emit(result);
  }

  private brandFilter(models: DeviceTypeDto[]): DeviceTypeDto[] {
    const manufacturer = this.filterForm.value.manufacturer;
    return !manufacturer
      ? models
      : models.filter((model) => model.brand.name === manufacturer);
  }

  private sourceFilter(models: DeviceTypeDto[]): DeviceTypeDto[] {
    const source = this.filterForm.value.source;
    return !source
      ? models
      : models.filter((model) => model.source.metadata.label === source);
  }

  private dataTagFilter(models: DeviceTypeDto[]): DeviceTypeDto[] {
    const dataTags = this.filterForm.value.dataTags;
    return !dataTags
      ? models
      : models.filter((model) => model.metadata.functions.includes(dataTags));
  }

  private keywordFilter(models: DeviceTypeDto[]): DeviceTypeDto[] {
    const keyword = this.filterForm.value.keyword.toLowerCase();
    return !keyword
      ? models
      : models.filter(
          (model) =>
            model.device_type_id.toLowerCase().includes(keyword) ||
            model.brand.name.toLowerCase().includes(keyword) ||
            model.metadata?.label?.toLowerCase().includes(keyword) ||
            model.source.metadata?.label?.toLowerCase().includes(keyword) ||
            model.metadata?.functions?.filter((tag) =>
              tag.toLowerCase().includes(keyword),
            ).length,
        );
  }

  private disabledFilter(models: DeviceTypeDto[]): DeviceTypeDto[] {
    const show = this.filterForm.value.showDisabled;
    return show ? models : models?.filter((model) => !model.disabled);
  }

  private buildFilterForm(): UntypedFormGroup {
    const form = new UntypedFormGroup({
      filter: new UntypedFormGroup({
        keyword: new UntypedFormControl(''),
        manufacturer: new UntypedFormControl(),
        source: new UntypedFormControl(),
        dataTags: new UntypedFormControl(),
        showDisabled: new UntypedFormControl(),
      }),
      tileView: new UntypedFormControl(true),
    });

    return form;
  }

  private get filterForm(): UntypedFormGroup {
    return this.form.get('filter') as UntypedFormGroup;
  }
}
