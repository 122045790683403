import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { debounceTime, startWith, tap } from 'rxjs/operators';
import { DeviceLightDto } from 'src/models/data-routing.models';
import { DevicePath } from '../routing-details.component';

@Component({
  selector: 'app-device-data-selection',
  templateUrl: './device-data-selection.component.html',
  styleUrls: ['./device-data-selection.component.scss'],
})
export class DeviceDataSelectionComponent implements OnInit {
  @Input() routedDevices: DeviceLightDto[];
  @Output() deviceDataSelected = new EventEmitter<DevicePath[]>();
  selectedDevices: DeviceLightDto[];
  filteredDevices: DeviceLightDto[];
  allSelected = new FormControl(true);
  filter = new FormControl('', { nonNullable: true });

  ngOnInit(): void {
    this.selectedDevices = this.routedDevices;
    this.filteredDevices = this.routedDevices;

    this.allSelected.valueChanges
      .pipe(
        startWith(this.allSelected.value),
        tap(
          (allSelected) =>
            (this.selectedDevices = allSelected ? this.routedDevices : []),
        ),
        tap(() => this.deviceDataSelected.emit(this.selectedData)),
      )
      .subscribe();

    this.filter.valueChanges
      .pipe(
        debounceTime(150),
        tap((value) => {
          const filterResult = this.routedDevices.filter(
            (devices) =>
              devices.device_name.toLowerCase().includes(value.toLowerCase()) ||
              devices.device_id.toLowerCase().includes(value.toLowerCase()),
          );
          this.selectedDevices = filterResult;
          this.filteredDevices = filterResult;
          this.deviceDataSelected.emit(this.selectedData);
        }),
      )
      .subscribe();
  }

  public isDeviceSelected(deviceId: string): boolean {
    return this.selectedDevices
      .map((device) => device.device_id)
      .includes(deviceId);
  }

  public addDeviceToSelection(device: DeviceLightDto): void {
    this.selectedDevices.push(device);
    this.deviceDataSelected.emit(this.selectedData);
  }

  public removeDeviceFromSelection(device: DeviceLightDto): void {
    this.selectedDevices = this.selectedDevices.filter(
      (dev) => dev.device_id !== device.device_id,
    );
    this.deviceDataSelected.emit(this.selectedData);
  }

  private get selectedData(): DevicePath[] {
    const dataArrays = this.selectedDevices
      .filter((device) => !!device.data.length)
      .map((device) =>
        device.data.map((data) => ({
          device,
          path: `${device.device_name},${device.hierarchy_prefix}${device.device_id}/${data}`,
        })),
      );
    return ([] as DevicePath[]).concat(...dataArrays);
  }
}
