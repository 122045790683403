<div class="banner mat-typography mat-elevation-z3">
  <h2>Import an existing Gateway from Mulititech DeviceHQ</h2>
  <button mat-icon-button [routerLink]="['/home/gateways']">
    <mat-icon>clear</mat-icon>
  </button>
</div>
<form class="form" [formGroup]="gatewayForm">
  <mat-form-field
    appearance="outline"
    hintLabel="ID of the Gateway found on DeviceHQ platform"
  >
    <mat-label>Serial</mat-label>
    <input matInput formControlName="gateway_id" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Site</mat-label>
    <mat-select formControlName="site">
      <mat-option *ngFor="let site of sites$ | async" [value]="site.code">
        {{ site.country.isoCode }} - {{ site.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="buttons">
    <button mat-button [routerLink]="['/home/gateways']" color="primary">
      Cancel
    </button>
    <div class="spacer"></div>
    <button
      mat-button
      (click)="submitForm()"
      [disabled]="!gatewayForm.valid"
      color="primary"
    >
      Submit
    </button>
  </div>
</form>
