export interface DeviceType {
  device_type_id: string;
  encoder_fucntion_name: string;
  decoder_function_name: string;
  metadata: { [key: string]: string };
  brand: string;
}

export interface DeviceTypeDto {
  device_type_id: string;
  encoder_function_name: string;
  decoder_function_name: string;
  disabled?: boolean;
  metadata: {
    functions: string[];
    label: string;
    [key: string]: unknown;
  };
  brand: Brand;
  source: Source;
  exposed_data?: { technical_fields: Field[]; business_fields: Field[] };
  passthrough?: boolean;
  battery_powered?: boolean;
  ranges?: MeasureRange[];
  decoder_blueprint?: DecoderBluePrint;
}

export interface MeasureRange {
  min: number;
  max: number;
  unit: string;
}

export interface Field {
  name: string;
  output_name?: string;
  type: BasicType;
}
export interface Brand {
  id: string;
  name: string;
  description: string;
  logoUrl: string | null;
}

export interface Source {
  source_id: string;
  metadata: { logo_url: string; label: string; [key: string]: unknown };
}

export enum DeviceSourceType {
  IOT_CORE = 'iot_core',
  LORA_FIREFLY = 'firefly',
  PUB_SUB = 'pub_sub',
  NIOTA = 'niota',
  ARO_CLOUD = 'aro_cloud',
  ENDRESS_HAUSER = 'endress_hauser',
  SIGFOX = 'sigfox',
}

export interface ParsedJSON {
  [key: string]: string | ParsedJSON[] | boolean | number | Date | ParsedJSON;
}

export interface JsonInputResult {
  json_sample: string;
  fields: MessageField[];
}

export interface MessageField {
  name: string;
  type: BasicType;
  output_name: string;
  duplicate_output?: boolean;
  deleted?: boolean;
}

export interface MadatoryMessageField {
  label: string;
  key_name: string;
  description: string;
  valid: boolean;
  optional: boolean;
  allowed_types: BasicType[];
}

export interface DeviceTypeInfo {
  device_model_name: string;
  battery_powered: boolean;
  description: string;
  source: string;
  brand: string;
  connectivity: string;
  installation: string;
  dimensions: string;
  weight: string;
  battery: string;
  battery_life: string;
  configurable: string;
  frequency: string;
  security: string;
  atex: boolean;

  data_tags?: string[];
  encoder_function_name?: string;
  decoder_function_name?: string;
}

export type BasicType =
  | 'string'
  | 'number'
  | 'bigint'
  | 'boolean'
  | 'symbol'
  | 'undefined'
  | 'object'
  | 'function';

export type DecoderBluePrint = JsonDecoderBluePrint | HexDecoderBluePrint;

export interface JsonDecoderBluePrint {
  type: DecoderType;
  config: {
    json_sample?: string;
    variables?: MessageField[];
  };
}

export interface HexDecoderBluePrint {
  type: DecoderType;
  config: {
    _switch: DecodingConfiguration;
    _default: DecodingConfiguration;
    _single: DecodingConfiguration;
    [key: string]: DecodingConfiguration;
  };
}

export enum DecoderType {
  PASSTHROUGH = 'passthrough',
  JSON = 'json',
  SINGLE = 'single',
  MULTI = 'multi',
}

export interface DecodingConfiguration {
  variables: {
    [key: string]: Variable;
  };
  parserElements: ParserElement[];
  calculations: Calculation[];
}

export interface ParserElement {
  bits: number;
  type: ParserElementType;
  target: string;
  littleEndian?: boolean;
  signComplement?: SignComplement;
  signed?: boolean;
}

export interface Variable {
  name: string;
  type: string;
  measurement_type: string;
  unit: string;
  order: number;
  write_business_field_unit: boolean;
  write_business_field: boolean;
  from_source?: boolean;
}

export interface Calculation {
  formula: string;
  target: string;
}

export type ParserElementType = 'int' | 'boolean' | 'string' | 'float' | 'hex';

export type SignComplement = '2' | '1';
