<div class="wrapper">
  <div class="header mat-elevation-z4">
    <h3 class="title">Devices</h3>
    <div class="spacer"></div>
    <button
      mat-flat-button
      *ngIf="auth.isTechnicalAdmin() || auth.isAdmin()"
      (click)="openMultiDownlink()"
      class="view-toggle-left view-toggle"
      [class.active]="router.url.includes('multi-downlink')"
    >
      <mat-icon>list</mat-icon>
    </button>
    <button
      mat-flat-button
      *ngIf="auth.isTechnicalAdmin() || auth.isAdmin()"
      (click)="openManager()"
      class="view-toggle view-toggle-right"
      [class.active]="router.url.includes('manager')"
    >
      <mat-icon>folder_open</mat-icon>
    </button>
  </div>
  <div class="filter mat-elevation-z4">
    <ng-template *ngTemplateOutlet="formTpl"></ng-template>
    <div class="send-container">
      <button
        mat-flat-button
        color="primary"
        [disabled]="!isValidSelection"
        matTooltipPosition="before"
        [matTooltip]="sendButtonTooltip"
        (click)="onSendDownlink()"
      >
        SEND DOWNLINK
      </button>
    </div>
  </div>

  <section class="table-container mat-elevation-z4">
    <table
      mat-table
      matSort
      (matSortChange)="resetSelection()"
      multiTemplateDataRows
      [dataSource]="dataSource"
      aria-describedby="list of devices"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isEntirePageSelected()"
            [indeterminate]="selection.hasValue() && !isEntirePageSelected()"
            color="primary"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            color="primary"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="site_label">
        <th
          mat-header-cell
          mat-sort-header
          disableClear="true"
          *matHeaderCellDef
        >
          Site
        </th>
        <td mat-cell *matCellDef="let device">
          {{ device.site_label }}
        </td>
      </ng-container>
      <ng-container matColumnDef="device_type_label">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          disableClear="true"
        >
          Model
        </th>
        <td mat-cell *matCellDef="let device">
          {{ device.device_type_label }}
        </td>
      </ng-container>
      <ng-container matColumnDef="source_label">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          disableClear="true"
        >
          Source
        </th>
        <td mat-cell *matCellDef="let device">
          {{ device.source_label }}
        </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          disableClear="true"
        >
          ID (EUI)
        </th>
        <td mat-cell *matCellDef="let device">
          {{ device.id }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          disableClear="true"
        >
          Name
        </th>
        <td mat-cell *matCellDef="let device" class="clip-text">
          {{ device.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="creation_date">
        <th
          mat-header-cell
          mat-sort-header
          disableClear="true"
          *matHeaderCellDef
          id="creation_date"
        >
          Creation date
        </th>
        <td
          mat-cell
          *matCellDef="let device"
          matTooltipPosition="before"
          [matTooltip]="device.creation_date_iso"
        >
          {{ device.creation_date | date: 'yyyy-MM-dd' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="last_downlink">
        <th
          mat-header-cell
          mat-sort-header
          disableClear="true"
          *matHeaderCellDef
          id="last_downlink"
        >
          Last downlink
        </th>
        <td
          mat-cell
          *matCellDef="let device"
          matTooltipPosition="before"
          [matTooltip]="device.last_downlink_iso"
        >
          {{ device.last_downlink | date: 'yyyy-MM-dd' || '--' }}
        </td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
        class="header-row"
      ></tr>
      <tr
        mat-row
        *matRowDef="let device; columns: displayedColumns"
        class="device-row"
      ></tr>
    </table>
  </section>
  <div class="footer mat-elevation-z6">
    <span class="selected-label">Selected</span
    ><span class="selected-counter"
      >{{ selection.selected.length }}/{{
        this.dataSource.paginator?.pageSize
      }}</span
    >
    <div></div>
    <mat-paginator
      matTooltipPosition="left"
      [matTooltip]="resetTooltip"
      [pageSizeOptions]="[10]"
      aria-label="Select page of devices"
      (page)="resetSelection()"
    ></mat-paginator>
  </div>
</div>

<ng-template #formTpl>
  <form [formGroup]="filterForm" *ngIf="filterForm">
    <mat-form-field class="filter-select">
      <mat-label>Site</mat-label>
      <mat-select
        formControlName="project_ids"
        multiple
        matTooltipPosition="below"
        [matTooltip]="resetTooltip"
      >
        <mat-option *ngFor="let site of sites$ | async" [value]="site.code">
          {{ site.country.isoCode }} -
          {{ site.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="filter-select">
      <mat-label>Source</mat-label>
      <mat-select
        formControlName="source"
        matTooltipPosition="below"
        [matTooltip]="resetTooltip"
      >
        <mat-option [value]="0">All</mat-option>
        <mat-option
          *ngFor="let source of sources$ | async"
          [value]="source.code"
          >{{ source.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field class="filter-select">
      <mat-label>Model</mat-label>
      <mat-select
        formControlName="device_type_id"
        matTooltipPosition="below"
        [matTooltip]="resetTooltip"
      >
        <mat-option [value]="0">All</mat-option>
        <mat-option
          *ngFor="let model of models$ | async"
          [value]="model?.device_type_id"
          >{{ model?.brand.name }} - {{ model?.metadata?.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <button
      matTooltipPosition="below"
      [matTooltip]="resetTooltip"
      mat-button
      (click)="resetForm()"
      *ngIf="this.filterForm.touched"
      class="reset-btn"
    >
      <mat-icon>history</mat-icon> <span> RESET FILTER</span>
    </button>
  </form>
</ng-template>
