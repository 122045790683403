import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { DeviceTypeDto } from 'src/models/device-type.models';
import { DataStoreService } from '../../../../../services/state/data/data-store.service';

@UntilDestroy()
@Component({
  selector: 'app-device-providing-model-select',
  templateUrl: './device-providing-model-select.component.html',
  styleUrls: ['./device-providing-model-select.component.scss'],
})
export class DeviceProvidingModelSelectComponent
  implements OnInit, ControlValueAccessor
{
  private _sourceFilter = new BehaviorSubject<string>('');

  @Input() set sourceFilter(value: string) {
    this._sourceFilter.next(value);
  }

  groupedTypes$ = combineLatest([
    this.data.groupedDeviceTypes$,
    this._sourceFilter.asObservable(),
  ]).pipe(
    map(([groups, filter]) =>
      filter
        ? groups.filter((group) => group?.source?.source_id === filter)
        : groups,
    ),
  );

  form = this.fb.group({
    source: [null, [Validators.required]],
    brand: [null, [Validators.required]],
    model: [null, [Validators.required]],
  });

  private _changeFn: (arg: unknown) => void;

  constructor(
    private data: DataStoreService,
    private fb: UntypedFormBuilder,
    @Optional() @Self() private _ngControl: NgControl,
  ) {
    if (this._ngControl) this._ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.form
      .get('source')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => this.form.patchValue({ brand: null }));

    this.form
      .get('brand')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => this.form.patchValue({ model: null }));

    this.form
      .get('model')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => this._changeFn(value));
  }

  writeValue(obj: DeviceTypeDto): void {
    this.groupedTypes$.pipe(first()).subscribe((groups) => {
      const source = groups?.find(
        (item) => item?.source?.source_id === obj?.source?.source_id,
      );
      const brand = source?.brands?.find(
        (item) => item?.brand?.name === obj?.brand?.name,
      );
      const model = brand?.models?.find(
        (item) => item?.device_type_id === obj?.device_type_id,
      );

      if (source && brand && model)
        this.form.patchValue({ source, brand, model });
    });
  }

  registerOnChange(fn: (arg: unknown) => void): void {
    this._changeFn = fn;
  }

  registerOnTouched(): void {}

  setDisabledState(value: boolean) {
    const method = value ? 'disable' : 'enable';
    for (const controlName in this.form.controls)
      this.form.get(controlName)?.[method]?.();
  }

  compareSourceFn(one, two) {
    return one?.source.source_id === two?.source.source_id;
  }
  compareBrandFn(one, two) {
    return one?.brand?.id === two?.brand?.id;
  }
  compareModelFn(one, two) {
    return one?.device_type_id === two?.device_type_id;
  }
}
