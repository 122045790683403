import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { icon, map, marker, tileLayer } from 'leaflet';
import { environment } from 'src/environments/environment';
import { DeviceDto } from 'src/models/device.models';
import { DeviceManagementService } from '../../../device-management.service';

const mapOptions = {
  layers: [
    tileLayer(environment.openStreetMap.layerTilesUrl, {
      maxZoom: 18,
      attribution: environment.openStreetMap.attribution,
    }),
  ],
};

@Component({
  selector: 'app-select-position-map',
  templateUrl: './select-position-map.component.html',
  styleUrls: ['./select-position-map.component.scss'],
})
export class SelectPositionMapComponent implements AfterViewInit, OnDestroy {
  constructor(private deviceManagement: DeviceManagementService) {}
  @Input() set device(value: DeviceDto) {
    if (value && value.metadata.lat && value.metadata.lng) {
      this.center = [value.metadata.lat, value.metadata.lng];
      if (this.map) {
        this.map.setView(this.center, 15);
      }
      if (this.marker) {
        this.marker.setLatLng(this.center);
      }
    }
  }
  @Output() setPosition = new EventEmitter<L.LatLng>();
  private map: L.Map;
  private marker: L.Marker;
  private center: L.LatLngExpression;

  private getSiteLocation() {
    this.deviceManagement.activeProjectLocation().subscribe((site) => {
      if (!this.center) {
        if (site && site.location) {
          this.center = [site.location.latitude, site.location.longitude];
        } else {
          this.center = [
            environment.openStreetMap.defaultMapCenter.lat,
            environment.openStreetMap.defaultMapCenter.lng,
          ];
        }
      }

      if (document.getElementById('mapSelect')) {
        this.map = map('mapSelect', mapOptions).setView(this.center, 15);
        this.marker = marker(this.center, {
          icon: icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/img/marker-icon.png',
            shadowUrl: 'assets/img/marker-shadow.png',
          }),
        }).addTo(this.map);

        this.map.on('click', (e) => {
          this.marker.setLatLng((e as L.LeafletMouseEvent).latlng);
          this.setPosition.emit((e as L.LeafletMouseEvent).latlng);
        });
      }
    });
  }

  ngAfterViewInit() {
    this.getSiteLocation();
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    }
  }
}
