<h2 *ngIf="!site" mat-dialog-title>Create a new site</h2>
<h2 *ngIf="site" mat-dialog-title>
  Edit site: {{ site?.metadata?.site_long_name }}
</h2>
<div mat-dialog-content>
  <div class="wrapper" [formGroup]="form">
    <div class="form-wrapper">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" [maxLength]="40" />
      </mat-form-field>

      <div class="row-2">
        <mat-form-field appearance="outline">
          <mat-label>Country</mat-label>
          <mat-select
            [panelClass]="site ? 'readonly-wrapper' : ''"
            formControlName="countryId"
          >
            <mat-option
              *ngFor="let country of countries$ | async"
              [value]="country.id"
            >
              {{ country.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Infra code</mat-label>
          <input
            [readonly]="site ? true : false"
            matInput
            class="uppercase"
            formControlName="infraSite"
          />
        </mat-form-field>
      </div>
      <div class="row-2">
        <mat-form-field appearance="outline">
          <mat-label>Organization</mat-label>
          <mat-select formControlName="organizationId">
            <mat-option
              *ngFor="let organization of organizations$ | async"
              [value]="organization.id"
            >
              {{ organization.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Firefly organization</mat-label>
          <input
            matInput
            formControlName="fireflyOrganization"
            [maxLength]="10"
          />
        </mat-form-field>
      </div>
      <ng-container formGroupName="location">
        <div class="row-2">
          <mat-form-field appearance="outline">
            <mat-label>Latitude</mat-label>
            <input matInput type="number" formControlName="latitude" />
            <mat-error
              *ngIf="form.get('location')?.get('latitude')?.errors?.allOrNone"
            >
              This field must be filled along with the longitude.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Longitude</mat-label>
            <input matInput type="number" formControlName="longitude" />
            <mat-error
              *ngIf="form.get('location')?.get('longitude')?.errors?.allOrNone"
            >
              This field must be filled along with the latitude.
            </mat-error>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
    <app-select-position
      [location]="form.controls.location.value"
      (setPosition)="updateLocation($event)"
    ></app-select-position>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button
    mat-button
    color="primary"
    (click)="submit()"
    cdkFocusInitial
    [disabled]="form.invalid || saving"
  >
    Confirm
  </button>
</div>
