<div class="form-container mat-elevation-z4">
  <div class="input">
    <h3>Choose or add a destination for your rule</h3>
    <mat-form-field appearance="outline">
      <mat-label>Destination Type</mat-label>
      <mat-select [formControl]="destinationType">
        <mat-option
          *ngFor="let destinationType of destinationTypesChoices"
          [value]="destinationType.id"
        >
          {{ destinationType.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Destination Name</mat-label>
      <mat-select [formControl]="destination">
        <mat-option
          *ngFor="let destination of destinationsChoices"
          [value]="destination.id"
        >
          {{ destination.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="warning-box" *ngIf="destinationType.value === 'bigquery'">
      <mat-icon class="warning-icon">warning</mat-icon>
      <p *ngIf="!destinationsChoices.length">
        There are no Bigquery outputs for your site.
      </p>
      <p>
        Please contact the support if the creation of a new Bigquery output is
        necessary!
      </p>
    </div>
    <mat-form-field
      appearance="outline"
      matTooltip="The name of the google sheet tab in which the data will be written"
      matTooltipPosition="above"
      *ngIf="destinationType.value === 'sheet' && !newSheetSelected"
    >
      <mat-label>Sheet Tab Name </mat-label>
      <input matInput [formControl]="tab" />
    </mat-form-field>
    <ng-container [formGroup]="newSheetForm" *ngIf="newSheetSelected">
      <mat-form-field
        appearance="outline"
        matTooltip="The title of your spreadsheet"
        matTooltipPosition="above"
      >
        <mat-label>New Sheet Name </mat-label>
        <input matInput formControlName="sheet_name" />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        matTooltip="The Url of the google sheet in which you want the data to be written"
        matTooltipPosition="above"
      >
        <mat-label>New Sheet URL</mat-label>
        <input matInput formControlName="sheet_id" />
        <mat-error *ngIf="newSheetForm.controls.sheet_id.hasError('url')"
          >Please enter a valid Google sheet url</mat-error
        >
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        matTooltip="The name of the google sheet tab in which the data will be written"
        matTooltipPosition="above"
      >
        <mat-label>New Sheet Tab Name </mat-label>
        <input matInput formControlName="tab_name" />
      </mat-form-field>
      <ng-container *ngIf="site.userAccessFlags && !!userAccessFlags.length">
        <h3>Control Groups</h3>
        <div class="check-container">
          <mat-checkbox
            *ngFor="let flag of userAccessFlags; let i = index"
            color="primary"
            (change)="onFlagChange($event.checked, flag)"
          >
            {{ flag }}
          </mat-checkbox>
        </div>
      </ng-container>
      <div class="warning-box">
        <mat-icon class="warning-icon">warning</mat-icon>
        <p>Make sure to share the google sheet with</p>
        <p>{{ gsServiceAccount }}</p>
      </div>
    </ng-container>

    <ng-container [formGroup]="newBigqueryForm" *ngIf="newBigQuerySelected">
      <mat-form-field appearance="outline">
        <mat-label>Dataset </mat-label>
        <input matInput formControlName="dataset" />
        <span matPrefix>{{ datasetPrefix }}</span>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Table </mat-label>
        <input matInput formControlName="table" />
      </mat-form-field>
      <ng-container *ngIf="site.userAccessFlags && !!userAccessFlags.length">
        <h3>Control Groups</h3>
        <div class="check-container">
          <mat-checkbox
            *ngFor="let flag of userAccessFlags; let i = index"
            color="primary"
            (change)="onFlagChange($event.checked, flag)"
          >
            {{ flag }}
          </mat-checkbox>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="selection-visual">
    <div class="book-image">
      <p>{{ destinationType.value || 'Destination' }}</p>
    </div>
  </div>
</div>
<div class="data-select mat-elevation-z4">
  <app-data-select-list
    [pattern]="pattern"
    [data]="data"
    (dataSelected)="dataSelected($event)"
  ></app-data-select-list>
</div>
<div class="sheet-data mat-elevation-z4">
  <app-sheet-data-builder
    *ngIf="newSheetSelected"
    [dataStream]="dataSelected$.asObservable()"
    (dataAssigned)="setNewSheetFields($event)"
  ></app-sheet-data-builder>
  <app-sheet-data-builder
    *ngIf="newBigQuerySelected"
    [dataStream]="dataSelected$.asObservable()"
    tableName="Table"
    (dataAssigned)="setNewBigqueryFields($event)"
  ></app-sheet-data-builder>
  <ng-container *ngFor="let sheet of sheets">
    <app-sheet-data-builder
      *ngIf="gsUpdateAllowed(sheet)"
      [columns]="getColumns(sheet)"
      [dataStream]="dataSelected$.asObservable()"
      (dataAssigned)="setNewSheetFields($event)"
      [disabled]="true"
    ></app-sheet-data-builder>
  </ng-container>
</div>
