import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { DeviceManagementService } from 'src/app/pages/main/device-management/device-management.service';
import { DevicesService } from 'src/app/services/http/devices.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { DeviceHierarchyStoreService } from 'src/app/services/state/data/device-hierarchy/device-hierarchy-store.service';
import { WizardStep } from 'src/models/app-routing.models';
import { HierarchyNode } from 'src/models/device-hierarchy.models';
import { DeviceTypeDto, SourceCode } from 'src/models/device-type.models';
import {
  DefaultDeviceInfo,
  DeviceDto,
  FireflyDeviceInfo,
  IotCoreDeviceInfo,
} from 'src/models/device.models';

@Injectable({
  providedIn: 'root',
})
export class DeviceCreationService {
  private readonly _activeStep$ = new BehaviorSubject<WizardStep | undefined>(
    undefined,
  );
  private readonly _selectedDeviceType$ = new BehaviorSubject<
    DeviceTypeDto | undefined
  >(undefined);

  constructor(
    private deviceHttp: DevicesService,
    private router: Router,
    private data: DataStoreService,
    private deviceHierarchy: DeviceHierarchyStoreService,
    private deviceManagement: DeviceManagementService,
    private snackBar: MatSnackBar,
  ) {}

  public setSelectedDeviceType(type: DeviceTypeDto): void {
    this._selectedDeviceType$.next(type);
  }

  public get selectedDeviceType(): DeviceTypeDto | undefined {
    return this._selectedDeviceType$.value;
  }

  public get selectedDeviceType$(): Observable<DeviceTypeDto | undefined> {
    return this._selectedDeviceType$.asObservable();
  }

  public get deviceTypeSelected(): boolean {
    return !!this._selectedDeviceType$.value;
  }

  public get activeStep$(): Observable<WizardStep | undefined> {
    return this._activeStep$.asObservable();
  }

  public setActiveStep(
    step: number,
    config: Pick<WizardStep, 'title'>[],
  ): void {
    const nextStep: WizardStep = {
      ...config[step - 1],
      totalSteps: config.length,
      step,
    };
    this._activeStep$.next(nextStep);
  }

  public createDevice(
    deviceInfo: FireflyDeviceInfo | DefaultDeviceInfo | IotCoreDeviceInfo,
    source: string,
  ): void {
    const path = this.deviceManagement.path;
    const selectedNode = this.deviceHierarchy.getSelectedNodeForPath(path);

    this.deviceHttp
      .createDevice(deviceInfo, source)
      .pipe(
        first(),
        tap((device) => this.data.addDevice(this.addDeviceTypeInfo(device))),
        tap((device) =>
          selectedNode.children.push({
            id: device.device_id,
            children: [],
            isDevice: true,
            isData: false,
            name: device.metadata.name,
          } as HierarchyNode),
        ),
        tap(() => this.deviceHierarchy.updateNode(selectedNode)),
        tap(() =>
          this.router.navigate(['/home/device/manager'], {
            queryParams: { path },
          }),
        ),
      )
      .subscribe();
  }

  public getRouteForDeviceDataSource(sourceCode: SourceCode): string {
    switch (sourceCode) {
      case 'firefly':
        return 'firefly';
      case 'iot_core':
        return 'iotcore';
      case 'pub_sub':
      case 'sigfox':
        this.snackBar.open(
          'Creation of devices from this source type is not yet possible!',
          'close',
        );
        return '';
      default:
        return 'generic';
    }
  }

  public createFireflyDevices(
    csv: string,
    hierarchyPrefix: string,
    deviceTypeId: string,
  ) {
    const payload = {
      csv,
      deviceTypeId,
      attributeTags: [],
      hierarchyPrefix:
        this.deviceManagement.buildHierarchyPrefix(hierarchyPrefix),
      projectIds: [this.deviceManagement.buildActiveProject(hierarchyPrefix)],
    };

    return this.deviceHttp.createDevices(payload, 'firefly').pipe(
      tap((res) => this.updateStoreWithMultipleDevices(res.created)),
      catchError((err) => {
        this.updateStoreWithMultipleDevices(err.error.created);
        return throwError(err);
      }),
    );
  }

  private updateStoreWithMultipleDevices(devices: DeviceDto[]) {
    const path = this.deviceManagement.path;
    const selectedNode = this.deviceHierarchy.getSelectedNodeForPath(path);

    devices
      .map((device) => this.addDeviceTypeInfo(device))
      .forEach((device) => {
        this.data.addDevice(device);
        selectedNode.children.push({
          id: device.device_id,
          children: [],
          isDevice: true,
          isData: false,
          name: device.metadata.name,
        } as HierarchyNode);
        this.deviceHierarchy.updateNode(selectedNode);
        this.deviceManagement.setSelectedNode(selectedNode);
      });
  }

  private addDeviceTypeInfo(device: DeviceDto) {
    const deviceType = this.data.getDeviceTypeByID(device.device_type_id!);
    return deviceType ? { ...device, device_type: deviceType } : device;
  }

  public moveDevices(deviceIds: string[], path: string) {
    return this.deviceHttp.moveDevices(
      deviceIds,
      this.deviceManagement.buildHierarchyPrefix(path),
      this.deviceManagement.buildActiveProject(path),
    );
  }

  public calibrateDevice(deviceId: string, calibrations: unknown) {
    return this.deviceHttp.setDeviceCalibration(deviceId, calibrations);
  }
}
