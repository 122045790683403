import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { DeviceTypeDto, DeviceTypeInfo } from 'src/models/device-type.models';
import { DataLoaderService } from '../../../../services/state/data/data-loader.service';

@UntilDestroy()
@Component({
  selector: 'app-model-creation-new',
  templateUrl: './model-creation.component.html',
  styleUrls: ['./model-creation.component.scss'],
})
export class ModelCreationComponent implements OnInit {
  // interface state
  imagePreviewUrl: string =
    (this.deviceType?.metadata.device_logo_url as string) || '';

  brands = this.data.brands$;

  // user input
  modelForm = this.buildModelForm;
  imageFile = new FormControl(null);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public deviceType: DeviceTypeDto | undefined,
    private dialogRef: MatDialogRef<ModelCreationComponent>,
    private data: DataStoreService,
    private dataLoader: DataLoaderService,
  ) {
    this._loadData();
  }

  private _loadData() {
    this.data.brands$
      .pipe(
        switchMap((state) =>
          state ? of(state) : this.dataLoader.loadBrands(),
        ),
        first(),
      )
      .subscribe();
  }
  private get buildModelForm() {
    const dt = this.deviceType;
    const md = dt?.metadata;
    return new FormGroup({
      device_model_name: new FormControl((md?.label as string) || '', [
        Validators.required,
        Validators.pattern(/^[^<>'";&=%\\/]+$/),
      ]),
      source: new FormControl(
        (dt?.source.source_id as string) || '',
        Validators.required,
      ),
      brand: new FormControl(dt?.brand.name ?? '', Validators.required),
      connectivity: new FormControl(
        (dt?.metadata.connectivity as string) || this.connectivityFromSource,
        Validators.required,
      ),

      battery_powered: new FormControl(
        !!dt?.battery_powered,
        Validators.required,
      ),
      description: new FormControl((md?.description as string) || ''),
      installation: new FormControl((md?.installation as string) || ''),
      dimensions: new FormControl((md?.dimensions as string) || ''),
      weight: new FormControl((md?.weight as string) || ''),
      battery: new FormControl((md?.battery as string) || ''),
      battery_life: new FormControl((md?.battery_life as string) || ''),
      configurable: new FormControl((md?.configurable as string) || ''),
      frequency: new FormControl((md?.frequency as string) || ''),
      security: new FormControl((md?.security as string) || ''),
      atex: new FormControl((md?.atex as boolean) || false),
      // firmware: this.fb.array([this.fb.control('default')]),
      // ranges: this.fb.array([]),

      encoder_function_name: new FormControl(dt?.encoder_function_name),
      decoder_function_name: new FormControl(dt?.decoder_function_name),
    });
  }

  private get connectivityFromSource() {
    const dt = this.deviceType;
    return dt ? (dt.source.source_id === 'firefly' ? 'LoRaWAN' : 'MQTT') : null;
  }

  ngOnInit(): void {
    this.manageConnectivityChanges.subscribe();
  }

  // get firmwareForms(): FormArray {
  //   return this.modelForm.controls['firmware'] as FormArray;
  // }

  // addFirmware() {
  //   this.firmwareForms.push(this.fb.control(''));
  // }

  // removeFirmware(index: number) {
  //   this.firmwareForms.removeAt(index);
  // }

  // get rangeTypeForms(): FormArray {
  //   return this.modelForm.controls['ranges'] as FormArray;
  // }

  // addRangeType() {
  //   const rangeTypeForm = this.fb.group({
  //     type: [],
  //     unit: [],
  //     ranges: this.fb.array([
  //       this.fb.group({
  //         min: [],
  //         max: [],
  //       }),
  //     ]),
  //   });
  //   this.rangeTypeForms.push(rangeTypeForm);
  // }

  // removeRangeType(index: number) {
  //   this.rangeTypeForms.removeAt(index);
  // }

  // ranges(rangeTypeForm: FormGroup): FormArray {
  //   return rangeTypeForm.controls.ranges as FormArray;
  // }

  // addRange(rangeTypeForm: FormGroup) {
  //   const ranges = rangeTypeForm.controls.ranges as FormArray;
  //   const rangeForm = this.fb.group({ min: [], max: [] });
  //   ranges.push(rangeForm);
  // }

  // removeRange(rangeTypeForm: FormGroup, rangeIndex: number) {
  //   const ranges = rangeTypeForm.controls.ranges as FormArray;
  //   ranges.removeAt(rangeIndex);
  // }

  // public get measurementTypeNames(): string[] {
  //   return Object.keys(measurementTypes);
  // }

  // public getMeasurementTypeUnits(type): any[] {
  //   return measurementTypes[type] || [];
  // }

  private get manageConnectivityChanges() {
    return this.modelForm.controls.connectivity.valueChanges.pipe(
      map((connectivity) =>
        connectivity === 'LoRaWAN' ? 'firefly' : 'iot_core',
      ),
      tap((source) => {
        const isFirefly = source === 'firefly';
        this.modelForm.controls.source.setValue(source);
        this.modelForm.controls.encoder_function_name.setValue(
          isFirefly ? 'generic_firefly_encoder' : null,
        );
        this.modelForm.controls.decoder_function_name.setValue(
          isFirefly ? 'generic_decoder' : null,
        );
      }),
    );
  }

  get formValues() {
    return {
      info: this.modelForm.value as DeviceTypeInfo,
      image: this.imageFile.value,
    };
  }
}
