import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { AuthService } from 'src/app/services/state/auth/auth.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { DeviceTypeDto, MeasureRange } from 'src/models/device-type.models';
import { FireflyDeviceInfo } from 'src/models/device.models';
import { Site } from 'src/models/site.models';
import { DeviceIdValidator } from '../../device-exists.validator';
import { DeviceManagementService } from '../../device-management.service';
import { DeviceCreation } from '../abstract-device-creation';
import { fireflyCreationSteps } from '../device-creation.config';
import { DeviceCreationService } from '../device-creation.service';

@Component({
  selector: 'app-firefly-device-creation',
  templateUrl: './firefly-device-creation.component.html',
  styleUrls: ['./firefly-device-creation.component.scss'],
})
export class FireflyDeviceCreationComponent
  extends DeviceCreation
  implements OnInit
{
  // interface
  activeStepConfig$ = this.deviceCreation.activeStep$;
  activeStepIndex: number;

  // interface data
  deviceClasses = this.data.deviceClasses;
  regions = this.data.regions;
  deviceType = this.deviceCreation.selectedDeviceType as DeviceTypeDto;
  site$: Observable<Site | undefined>;
  userAccessFlags = this.authService.claims?.accessFlags || [];

  // data collection
  dataRates = this.data.dataRates();
  form = this.buildForm();
  deviceControlGroups = new Set<string>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private data: DataStoreService,
    private deviceCreation: DeviceCreationService,
    public deviceManagement: DeviceManagementService,
    public authService: AuthService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.stepQueryParamsHandler$.subscribe();
    this.pathQueryParamHandler$.subscribe();
    this.dataRateHandler$.subscribe();
  }

  private get stepQueryParamsHandler$(): Observable<number> {
    return this.route.queryParams.pipe(
      map((params) => parseInt(params.step, 10)),
      tap((step) => (this.activeStepIndex = step)),
      tap((step) =>
        this.deviceCreation.setActiveStep(step, fireflyCreationSteps),
      ),
      filter((step) => !this.stepValidator(step - 1)),
      tap(() =>
        this.router.navigate([], {
          queryParams: { step: 1 },
          queryParamsHandling: 'merge',
        }),
      ),
    );
  }

  private get pathQueryParamHandler$(): Observable<string> {
    return this.route.queryParams.pipe(
      map((params) => params.path ?? ''),
      tap((path) => {
        this.deviceManagement.setPath(path);
        this.site$ = this.data.findSite(this.deviceManagement.activeProject);
      }),
    );
  }

  private get dataRateHandler$(): Observable<string> {
    return this.technicalForm.controls.region.valueChanges.pipe(
      tap((region) => {
        this.dataRates = this.data.dataRates(region);
        this.technicalForm.controls.dataRate.setValue(this.dataRates[0].value);
      }),
    );
  }

  get rangeUnits(): string[] {
    const units: Set<string> = new Set();
    this.deviceType.ranges?.forEach((range) => units.add(range.unit));
    return Array.from(units);
  }

  getRangesForUnit(unit: string): MeasureRange[] {
    return this.deviceType.ranges?.filter((range) => range.unit === unit) || [];
  }

  public stepValidator(step = this.activeStepIndex): boolean {
    const general = this.generalForm.valid;
    const technical = this.technicalForm.valid;
    const position = this.positionForm.valid;
    const metadata = this.metadataForm.valid;

    switch (step) {
      case 1:
        return general;
      case 2:
        return technical && general;
      case 3:
        return position && technical && general;
      default:
        return metadata && position && technical && general;
    }
  }

  public onFlagChange(checked: boolean, flag: string): void {
    checked
      ? this.deviceControlGroups.add(flag)
      : this.deviceControlGroups.delete(flag);
  }

  public submitForm(): void {
    const source = this.deviceType?.source.code;
    this.generalForm.value.EUI = this.generalForm.value.EUI.toUpperCase();
    this.technicalForm.value.applicationKey =
      this.technicalForm.value.applicationKey.toUpperCase();
    const deviceInfo: FireflyDeviceInfo = {
      ...this.generalForm.value,
      ...this.generalForm.value,
      ...this.technicalForm.value,
      attributeTags: Array.from(this.deviceControlGroups),
      projectIds: [this.deviceManagement.activeProject],
      metadata: {
        ...this.positionForm.value,
        ...this.metadataForm.value,
        description: this.descriptionFormControl.value,
      },
    };
    this.deviceCreation.createDevice(deviceInfo, source);
  }

  private buildForm(): UntypedFormGroup {
    const form = new UntypedFormGroup({
      general: new UntypedFormGroup({
        name: new UntypedFormControl(null, Validators.required),
        hierarchyPrefix: new UntypedFormControl(
          this.deviceManagement.hierarchyPrefix,
          Validators.required,
        ),
        deviceTypeId: new UntypedFormControl(
          this.deviceType?.device_type_id,
          Validators.required,
        ),
        EUI: new UntypedFormControl(
          null,
          [
            Validators.required,
            Validators.minLength(16),
            Validators.pattern(/[0-9A-Fa-f]+$/),
          ],
          [DeviceIdValidator.createValidator(this.deviceManagement)],
        ),
        unit: new UntypedFormControl({
          value: this.deviceType.ranges ? this.rangeUnits[0] : null,
          disabled: !this.deviceType.ranges,
          validators: [Validators.required],
        }),
        range: new UntypedFormControl(
          {
            value: this.deviceType.ranges
              ? this.getRangesForUnit(this.rangeUnits[0])[0]
              : null,
            disabled: !this.deviceType.ranges,
          },
          [Validators.required],
        ),
      }),
      technical: new UntypedFormGroup({
        // deviceProfileId: new FormControl(null, Validators.required),
        // applicationEUI: new FormControl(null, [

        //   Validators.minLength(16),
        //   Validators.pattern(/[0-9A-Fa-f]+$/),
        // ]),
        applicationKey: new UntypedFormControl(null, [
          Validators.required,
          Validators.minLength(32),
          Validators.pattern(/[0-9A-Fa-f]+$/),
        ]),
        region: new UntypedFormControl(
          this.regions[0].value,
          Validators.required,
        ),
        dataRate: new UntypedFormControl(
          this.dataRates[0].value,
          Validators.required,
        ),
        activationType: new UntypedFormControl('OTAA', Validators.required),
        isClassC: new UntypedFormControl(false, Validators.required),
      }),
      position: new UntypedFormGroup({
        lat: new UntypedFormControl(null, Validators.required),
        lng: new UntypedFormControl(null, Validators.required),
      }),
      metaData: new UntypedFormGroup({}),
      description: new UntypedFormControl(''),
    });
    return form;
  }
}
