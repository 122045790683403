<form [formGroup]="form" *ngIf="form" class="filter">
  <ng-container formGroupName="filter">
    <mat-form-field>
      <mat-label>Search</mat-label>
      <input matInput formControlName="keyword" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Manufacturer</mat-label>
      <mat-select formControlName="manufacturer">
        <mat-option [value]="0">All</mat-option>
        <mat-option
          *ngFor="let manufacturer of manufacturers"
          [value]="manufacturer.name"
        >
          {{ manufacturer.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Source</mat-label>
      <mat-select formControlName="source">
        <mat-option [value]="0">All</mat-option>
        <mat-option
          *ngFor="let source of sources"
          [value]="source.metadata.label"
          >{{ source.metadata.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Data Tag</mat-label>
      <mat-select formControlName="dataTags">
        <mat-option [value]="0">All</mat-option>
        <mat-option *ngFor="let tag of tags" [value]="tag">
          {{ tag | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle
      formControlName="showDisabled"
      color="primary"
      class="filter-field"
      *ngIf="showViewOption && auth.isAdmin()"
      >show disabled models</mat-slide-toggle
    >
  </ng-container>

  <mat-slide-toggle
    formControlName="tileView"
    color="primary"
    class="filter-field"
    *ngIf="showViewOption"
    >Tile View</mat-slide-toggle
  >
</form>
