import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function allOrNoneValidator(fields: string[]): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const controls = fields.map((field) => formGroup.get(field));
    const values = controls.map((control) => control?.value);

    controls.forEach((control) => control?.setErrors(null));

    const filledCount = values.filter((value) => !!value).length;

    if (filledCount === 0 || filledCount === fields.length) {
      return null;
    }

    const error = { allOrNone: true };

    controls.forEach((control, index) => {
      if (!values[index] && control) {
        control.markAsTouched(); // Mark field as touched to trigger the error display
        control.setErrors(error);
      }
    });

    return error;
  };
}
