import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { DevicesService } from 'src/app/services/http/devices.service';
import { AuthService } from 'src/app/services/state/auth/auth.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { DeviceHierarchyStoreService } from 'src/app/services/state/data/device-hierarchy/device-hierarchy-store.service';
import { HierarchyNode } from 'src/models/device-hierarchy.models';
import { DeviceDto } from 'src/models/device.models';
import { Site } from 'src/models/site.models';
import { ConfirmationDialogComponent } from '../../../components/confirmation-dialog/confirmation-dialog.component';
import { DeviceMoveConfirmationDialogComponent } from './device-move-confirmation-dialog/device-move-confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DeviceManagementService {
  private readonly _path$ = new BehaviorSubject<string>('');
  private readonly _view$ = new BehaviorSubject<'manager' | 'multi-downlink'>(
    'manager',
  );
  private readonly _selectedNode$ = new BehaviorSubject<HierarchyNode>({
    id: 'root',
    name: 'root',
    isDevice: false,
    isData: false,
    children: [],
  });

  // Data that will be transferred. Use a variable because the DOM event only accepts strings.
  private _dragData: DeviceDto[] = [];
  private _dragItem?: DeviceDto;

  constructor(
    private auth: AuthService,
    private data: DataStoreService,
    private deviceHttp: DevicesService,
    private deviceHierarchy: DeviceHierarchyStoreService,
    private dialog: MatDialog,
  ) {}

  public setSelectedNode(node: HierarchyNode): void {
    this._selectedNode$.next(node);
  }

  public get selectedNode$(): Observable<HierarchyNode> {
    return this._selectedNode$.asObservable();
  }

  public get selectedNode(): HierarchyNode {
    return this._selectedNode$.value;
  }

  public resetSelectedNode(): void {
    this._selectedNode$.next({
      id: 'root',
      name: 'root',
      isDevice: false,
      isData: false,
      children: [],
    });
  }

  public addFolder(folder: HierarchyNode): void {
    const selectedNode = this.selectedNode;
    let children = selectedNode.children;
    children.push(folder);
    children = [
      ...this.deviceHierarchy.getSortedFolders(children),
      ...this.deviceHierarchy.getSortedDevices(children),
    ];
    this._selectedNode$.next(selectedNode);
    this.deviceHierarchy.updateNode(selectedNode);
  }

  public setPath(path: string): void {
    this._path$.next(path);
  }

  public get path$(): Observable<string> {
    return this._path$.asObservable();
  }

  public get path(): string {
    return this._path$.value;
  }

  public resetPath(): void {
    this._path$.next('');
  }

  public setView(view: 'manager' | 'multi-downlink'): void {
    this._view$.next(view);
  }

  public get view(): 'manager' | 'multi-downlink' {
    return this._view$.value;
  }

  public get view$(): Observable<'manager' | 'multi-downlink'> {
    return this._view$.asObservable();
  }

  public resetView(): void {
    this._view$.next('manager');
  }

  public get activeProject(): string {
    return this.buildActiveProject();
  }

  public get hierarchyPrefix(): string {
    return this.buildHierarchyPrefix();
  }

  public userHasWriteRights(project?: string): boolean {
    return this.auth.hasWriteRightsForProject(project ?? this._path$.value);
  }

  public deleteDevice(device: DeviceDto) {
    const node = this._selectedNode$.value;
    node.children = node.children.filter(
      (node) => node.id !== device.device_id,
    );
    return this.deviceHttp.deleteDevice(device).pipe(
      tap(() => {
        this.data.deleteDevice(device.device_id);
      }),
      tap(() => this.setSelectedNode(node)),
      tap(() => this.deviceHierarchy.updateNode(node)),
    );
  }

  public checkIfDeviceExists(deviceId: string): Observable<boolean> {
    return of(
      !!this.data.devices?.find((device) => device.device_id === deviceId),
    );
  }

  public activeProjectLocation(): Observable<Site | undefined> {
    const projectTag = this.activeProject;
    if (projectTag && projectTag !== '') {
      return this.deviceHttp.getSite(projectTag);
    } else {
      return of(undefined);
    }
  }

  public toggleDragItem(item: DeviceDto, add: boolean) {
    if (add) this._dragData.push(item);
    else this._dragData = this._dragData.filter((device) => device !== item);
  }

  public resetDragData() {
    this._dragData = [];
  }

  public hasDragData() {
    return !!this._dragData.length;
  }

  public startDraggingItem(event: DragEvent, item: DeviceDto) {
    event.dataTransfer!.effectAllowed = 'move';
    if (!this._dragData.includes(item)) this._dragItem = item;
  }

  public dropDraggedItemOnNode(event: DragEvent, node: HierarchyNode) {
    event.preventDefault();

    const targetName = node.name;

    const deviceIds = this._dragData.map((device) => device.device_id);
    if (this._dragItem) deviceIds.push(this._dragItem.device_id);

    if (node === this._selectedNode$.value)
      return void this.dialog.open(ConfirmationDialogComponent, {
        data: { html: `This device is already on the site "${targetName}" !` },
      });

    return this.dialog
      .open(DeviceMoveConfirmationDialogComponent, {
        data: {
          originNode: this._selectedNode$.value,
          destinationNode: node,
          deviceIds,
        },
      })
      .afterClosed()
      .pipe(
        tap(() => (this._dragItem = undefined)),
        filter((v) => !!v),
        map(() => deviceIds),
      );
  }

  public allowDroppingDraggedItem(event: DragEvent) {
    event.preventDefault();
    event.dataTransfer!.dropEffect = 'move';
  }

  public preventDroppingDraggedItem(event: DragEvent) {
    event.preventDefault();
    event.dataTransfer!.dropEffect = 'none';
  }

  public buildActiveProject(path = this._path$.value) {
    if (path) {
      return path
        .split('/')
        .filter((chunk) => chunk.includes('site_'))
        .toString();
    }
    return '';
  }

  public buildHierarchyPrefix(path = this._path$.value) {
    const prefix = path.split('/').slice(2).join('/');
    return prefix.length ? '/' + prefix + '/' : '/';
  }

  public isFireflyConfiguredInSelectedSite(): boolean {
    const selectedSiteId = this.path.split('/')[1];
    const selectedSite = this.data.sites?.find(
      (site) => site.code === selectedSiteId,
    );
    if (!selectedSite) {
      console.error('Site not found in loaded data, ignoring Firefly check');
      return true;
    }
    return !!selectedSite.fireflyOrganization;
  }
}
