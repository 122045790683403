import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { catchError, of, tap } from 'rxjs';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { CreateSiteDTO, Site } from 'src/models/site.models';
import { allOrNoneValidator } from './all-or-none-validator.directive';

@Component({
  selector: 'app-site-creation',
  templateUrl: './site-creation.component.html',
  styleUrls: ['./site-creation.component.scss'],
})
export class SiteCreationComponent implements OnInit {
  form = this.buildForm;
  countries$ = this.data.countries$;
  organizations$ = this.data.organizations$;
  saving = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public site: Site | undefined,
    private data: DataStoreService,
    private dataLoader: DataLoaderService,
    private snackBar: MatSnackBar,
    private dialogRef: MatLegacyDialogRef<SiteCreationComponent>,
  ) {}

  ngOnInit(): void {
    if (this.site) {
      this.prefillForm(this.site);
    }
  }

  private get buildForm(): FormGroup {
    return new FormGroup({
      name: new FormControl('', Validators.required),
      countryId: new FormControl('', Validators.required),
      location: new FormGroup(
        {
          latitude: new FormControl(null),
          longitude: new FormControl(null),
        },
        { validators: allOrNoneValidator(['latitude', 'longitude']) },
      ),
      organizationId: new FormControl('', Validators.required),
      fireflyOrganization: new FormControl(),
      infraSite: new FormControl('', Validators.required),
    });
  }

  private prefillForm(site: Site): void {
    this.form.setValue({
      name: site.name,
      countryId: site.country.id,
      location: {
        latitude: site.location?.latitude ?? null,
        longitude: site.location?.longitude ?? null,
      },
      organizationId: site.organization.id,
      fireflyOrganization: site.fireflyOrganization,
      infraSite: site.infraSite,
    });
  }

  public get createSite(): CreateSiteDTO {
    return {
      siteId: this.site?.code ?? '',
      ...this.form.value,
      alertEnabled: this.site?.alertEnabled ?? false,
      alertUnixCron: this.site?.alertUnixCron ?? null,
      fireflyOrganization: this.site?.fireflyOrganization?.length
        ? this.site?.fireflyOrganization
        : undefined,
      location:
        this.site?.location?.latitude && this.site?.location?.longitude
          ? this.site.location
          : null,
    };
  }

  public async submit() {
    this.saving = true;
    if (!this.site?.code) {
      this.dataLoader
        .createSite(this.createSite)
        .pipe(
          tap(() => {
            this.snackBar.open('Site was successfully created!', 'Close');
            this.dialogRef.close();
          }),
          catchError((error) => {
            this.saving = false;
            return of(error);
          }),
        )
        .subscribe();
    } else {
      this.dataLoader
        .updateSite(this.site.code, this.createSite)
        .pipe(
          tap(() => {
            this.snackBar.open('Site was successfully updated!', 'Close');
            this.dialogRef.close();
          }),
          catchError((error) => {
            this.saving = false;
            return of(error);
          }),
        )
        .subscribe();
    }
  }

  public updateLocation(e: { lat: number; lng: number }) {
    this.form.controls.location.setValue({
      latitude: +e.lat.toFixed(7),
      longitude: +e.lng.toFixed(7),
    });
  }
}
