import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { InterfaceStateService } from 'src/app/services/state/interface/interface-store.service';

import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { RouteDto, destinationValues } from 'src/models/data-routing.models';
import { Site } from 'src/models/site.models';

@UntilDestroy()
@Component({
  selector: 'app-routing-filter',
  templateUrl: './routing-filter.component.html',
  styleUrls: ['./routing-filter.component.scss'],
})
export class RoutingFilterComponent implements OnInit {
  @Input() routings: RouteDto[];
  @Input() sites: Site[];
  @Output() routingsFiltered = new EventEmitter<RouteDto[]>();
  @Output() tileViewActive = new EventEmitter<boolean>();
  destinations = destinationValues();
  form: UntypedFormGroup;

  constructor(
    private data: DataStoreService,
    private interfaceState: InterfaceStateService,
  ) {}

  ngOnInit(): void {
    this.interfaceState.routingFilter$
      .pipe(
        untilDestroyed(this),
        tap((routingForm) => (this.form = routingForm)),
      )
      .subscribe();

    merge(
      this.filterForm.valueChanges,
      this.data.routings$.pipe(
        tap((routings) => {
          this.routings = routings ? routings : [];
        }),
      ),
    )
      .pipe(
        startWith(this.filterForm.value),
        tap(() => {
          this.emitFilteredRoutings();
        }),
      )
      .subscribe();

    this.tileView.valueChanges
      .pipe(
        startWith(this.tileView.value),
        tap((val) => this.tileViewActive.emit(val)),
      )
      .subscribe();
  }

  private emitFilteredRoutings() {
    let result = this.routings;

    if (result) {
      result = this.destinationFilter(result as RouteDto[]);
      result = this.siteFilter(result);
      result = this.keywordFilter(result);
      this.routingsFiltered.emit(result);
    }
  }

  private destinationFilter(routings: RouteDto[]): RouteDto[] {
    const dest = this.filterForm.value.destination;
    return !dest
      ? routings
      : routings.filter((route) => route.destination_type === dest);
  }

  private siteFilter(routings: RouteDto[]): RouteDto[] {
    const site = this.filterForm.value.site;
    return !site
      ? routings
      : routings.filter((route) => {
          const chunks = route.pattern.split('/');
          const interfaceTagId = chunks[1];
          return interfaceTagId === site;
        });
  }

  private keywordFilter(routings: RouteDto[]): RouteDto[] {
    const keyword = this.filterForm?.value?.keyword?.toLowerCase();

    return !keyword
      ? routings
      : routings
          .filter(
            (route) =>
              route.name && route.destination_type && route.pattern && route.id,
          )
          .filter(
            (route) =>
              route.name.toLowerCase().includes(keyword) ||
              route.destination_type.toLowerCase().includes(keyword) ||
              route.pattern.toLowerCase().includes(keyword) ||
              route.id.toLowerCase().includes(keyword) ||
              !!route.devices!.some(
                (device) => device.device_id.toLocaleLowerCase() === keyword,
              ),
          );
  }

  get filterForm(): UntypedFormGroup {
    return this.form.get('filter') as UntypedFormGroup;
  }

  get tileView(): UntypedFormControl {
    return this.form.controls.tileView as UntypedFormControl;
  }
}
