<mat-card
  (click)="emitSelection()"
  [class.clickable]="selectable"
  [class.disabled]="model.disabled"
>
  <mat-card-header [class.disabled]="model.disabled">
    <mat-card-title class="clip">
      {{ model?.metadata?.label }}
    </mat-card-title>
    <mat-card-subtitle>
      <ng-container
        *ngFor="
          let tag of model.metadata.functions?.slice(0, 2);
          let last = last
        "
      >
        <span
          >{{ tag | titlecase }}{{ (!last && ' / ') || '' }}</span
        > </ng-container
      ><span *ngIf="model.metadata.functions?.length > 2">/ ...</span>
    </mat-card-subtitle>
    <img
      [src]="model?.brand?.logoUrl"
      alt="Brand logo"
      mat-card-avatar
      [matTooltip]="model?.brand?.name"
      matTooltipPosition="below"
    />
  </mat-card-header>

  <img
    [src]="
      model?.metadata?.device_logo_url || './assets/img/no_image_available.png'
    "
    alt="Device logo"
    mat-card-image
  />

  <img
    class="source"
    [src]="model?.source?.metadata?.logo_url"
    alt="Data source logo"
    [matTooltip]="model?.source?.metadata?.label"
    matTooltipPosition="before"
  />

  <mat-card-actions>
    <ng-container *ngIf="editable && model.metadata.created_by_api">
      <button
        *ngIf="auth.isAdmin()"
        mat-mini-fab
        color="primary"
        (click)="toggleDisable($event)"
        matTooltipPosition="below"
        matTooltip="disable device type"
      >
        <mat-icon *ngIf="!model.disabled">do_not_disturb_on</mat-icon>
        <mat-icon *ngIf="model.disabled">do_not_disturb_off</mat-icon>
      </button>
      <button
        *ngIf="auth.isAdmin()"
        mat-mini-fab
        color="warn"
        (click)="emitDelete($event)"
        matTooltipPosition="below"
        matTooltip="delete device type"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="editable && !model.metadata.created_by_api">
      <button
        *ngIf="auth.isAdmin()"
        mat-mini-fab
        color="primary"
        (click)="toggleDisable($event)"
        matTooltipPosition="below"
        matTooltip="disable device type"
      >
        <mat-icon *ngIf="!model.disabled">do_not_disturb_on</mat-icon>
        <mat-icon *ngIf="model.disabled">do_not_disturb_off</mat-icon>
      </button>
    </ng-container>
  </mat-card-actions>
</mat-card>
