<div class="list-management" *ngIf="auth.isAdmin()">
  <section class="filter-container mat-elevation-z4" *appLoader="sites$">
    <div *ngIf="(screensizeIndex$ | async) >= 2">
      <ng-template *ngTemplateOutlet="formTpl"></ng-template>
    </div>
    <mat-expansion-panel
      *ngIf="(screensizeIndex$ | async) < 2"
      class="mat-elevation-z0"
    >
      <mat-expansion-panel-header>
        <mat-panel-title> Filter </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template *ngTemplateOutlet="formTpl"></ng-template>
    </mat-expansion-panel>
  </section>
  <section class="list" *appLoader="sites$">
    <div class="list-header mat-elevation-z4">
      <div class="list-row">
        <div class="list-col clip-text">Infra code</div>
        <div class="list-col clip-text">Name</div>
        <div class="list-col">Country</div>
        <div class="list-col">Organization</div>
      </div>
    </div>
    <div class="list-body">
      <div
        class="list-row blue-bg clickable"
        *ngFor="let site of filteredSites$ | async"
        (click)="editSite(site)"
      >
        <div class="list-col clip-text">{{ site.infraSite }}</div>
        <div class="list-col clip-text">{{ site.name }}</div>
        <div class="list-col">{{ site.country.name }}</div>
        <div class="list-col">{{ site.organization.name }}</div>
      </div>
    </div>
  </section>
  <button
    mat-fab
    color="primary"
    class="floating-button"
    *ngIf="auth.isAdmin()"
    matTooltipPosition="below"
    matTooltip="add a new site"
    (click)="createSite()"
  >
    <mat-icon>add</mat-icon>
  </button>

  <ng-template #formTpl>
    <app-site-filter
      [sites]="sites$ | async"
      (sitesFiltered)="updateFilteredSites($event)"
    ></app-site-filter>
  </ng-template>
</div>
