<div class="list-management-filter">
  <form [formGroup]="form" *ngIf="form" class="filter">
    <mat-form-field class="filter-field">
      <mat-label>Name</mat-label>
      <input matInput formControlName="search" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <mat-form-field class="filter-field">
      <mat-label>Site</mat-label>
      <mat-select formControlName="site">
        <mat-option value="">All</mat-option>
        <mat-option *ngFor="let site of sites$ | async" [value]="site.code">
          {{ site.country.isoCode }} -
          {{ site.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="filter-field">
      <mat-label>Destination</mat-label>
      <mat-select formControlName="destination">
        <mat-option value="">All</mat-option>
        <mat-option
          *ngFor="let routingDestinationType of routingDestinationTypes"
          [value]="routingDestinationType.id"
        >
          {{ routingDestinationType.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
