import { Component, Inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { LatLng } from 'leaflet';
import { shareReplay } from 'rxjs/operators';
import { AuthService } from 'src/app/services/state/auth/auth.service';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import {
  DeviceDto,
  DeviceUpdateInfo,
  PublicKeyFormat,
} from 'src/models/device.models';
import { DeviceCreation } from '../device-creation/abstract-device-creation';

@Component({
  selector: 'app-device-update',
  templateUrl: './device-update.component.html',
  styleUrls: ['./device-update.component.scss'],
})
export class DeviceUpdateComponent extends DeviceCreation {
  userAccessFlags = this.authService.claims?.accessFlags || [];
  deviceControlGroups = new Set<string>();
  editForm = new UntypedFormGroup({
    device_id: new UntypedFormControl(this.data.device.device_id),
    name: new UntypedFormControl(
      this.data.device.metadata.name,
      Validators.required,
    ),
    description: new UntypedFormControl(
      this.data.device.metadata.description || '',
    ),
    credentials: new UntypedFormGroup({
      key: new UntypedFormControl(null),
      format: new UntypedFormControl(
        PublicKeyFormat.RSA_X509_PEM,
        Validators.required,
      ),
    }),
    lat: new UntypedFormControl(this.data.device.metadata.lat),
    lng: new UntypedFormControl(this.data.device.metadata.lng),
  });
  loading = false;

  fileUploadForm = new UntypedFormControl(null, Validators.required);
  certificate$ = this.fileUploadForm.valueChanges.pipe(shareReplay(1));

  constructor(
    public dialogRef: MatDialogRef<DeviceUpdateComponent>,
    private dataLoader: DataLoaderService,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: { device: DeviceDto },
  ) {
    super();
  }

  onSetPosition(coords: LatLng) {
    this.editForm.controls.lat.setValue(coords.lat);
    this.editForm.controls.lng.setValue(coords.lng);
  }

  public onFlagChange(checked: boolean, flag: string): void {
    checked
      ? this.deviceControlGroups.add(flag)
      : this.deviceControlGroups.delete(flag);
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    const updateInfo: DeviceUpdateInfo = {
      device_id: this.editForm.value.device_id as string,
      name: this.editForm.value.name as string,
      description: this.editForm.value.description as string,
      lat: this.editForm.value.lat as string,
      lng: this.editForm.value.lng as string,
      attributeTags: Array.from(this.deviceControlGroups) as string[],
    };
    if (
      this.editForm.value.credentials.key &&
      this.editForm.value.credentials.format
    ) {
      updateInfo.credentials = {
        key: this.editForm.value.credentials.key as string,
        format: this.editForm.value.credentials.format as PublicKeyFormat,
      };
    }
    this.dataLoader
      .updateDevice(updateInfo)
      .subscribe((dev) => this.dialogRef.close(dev));
  }

  deviceHasFlag(flag: string): boolean {
    return (this.data.device as DeviceDto).attribute_tags
      .map((tag) => tag.tag_id)
      .includes(flag);
  }

  fileInputChange(fileInputEvent: Event) {
    const target = fileInputEvent.target as HTMLInputElement;
    const file = target.files![0];
    const fileReader = new FileReader();
    this.fileUploadForm.setValue(file);

    if (
      file &&
      (file.type.includes('x-x509-ca-cert') || file.type.includes('pkix-cert'))
    ) {
      fileReader.onload = () => {
        const res = fileReader.result as string;
        if (
          res.includes('-----BEGIN CERTIFICATE-----') &&
          res.includes('-----END CERTIFICATE-----')
        ) {
          this.editForm.value.credentials.key = res;
        } else {
          this.fileUploadForm.setErrors({ certFormat: true });
          this.editForm.value.credentials.key = null;
        }
      };

      fileReader.readAsText(file);
    } else {
      this.fileUploadForm.setErrors({ certFormat: true });
      this.editForm.value.credentials.key = null;
    }
  }

  isIotCore(): boolean {
    return this.data.device.device_type.source.code === 'iot_core';
  }
}
