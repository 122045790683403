import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { merge } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';

import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { Gateway } from 'src/models/gateway.models';
import { Site } from 'src/models/site.models';

@Component({
  selector: 'app-gateway-filter',
  templateUrl: './gateway-filter.component.html',
  styleUrls: ['./gateway-filter.component.scss'],
})
export class GatewayFilterComponent implements OnInit {
  @Input() gateways: Gateway[];
  @Input() sites: Site[];
  @Output() gatewaysFiltered = new EventEmitter<Gateway[]>();

  form: UntypedFormGroup;

  constructor(private data: DataStoreService) {}

  ngOnInit(): void {
    this.form = this.buildFilterForm();

    merge(
      this.form.valueChanges,
      this.data.gateways$.pipe(
        tap((gw) => {
          this.gateways = gw ? gw : [];
        }),
      ),
    )
      .pipe(
        startWith(this.form.value),
        tap(() => {
          this.emitFilteredGateways();
        }),
      )
      .subscribe();
  }

  private emitFilteredGateways() {
    const result = this.siteFilter(this.gateways);
    this.gatewaysFiltered.emit(result);
  }

  private siteFilter(gateways: Gateway[]): Gateway[] {
    const siteKeyword = this.form.value.site;
    return !siteKeyword
      ? gateways
      : gateways.filter((gw) => gw.tags.includes(siteKeyword));
  }

  private buildFilterForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      site: new UntypedFormControl(null),
    });
  }
}
