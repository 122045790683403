import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { MatSnackBar } from '@angular/material/snack-bar';
import { map, mergeMap, Observable } from 'rxjs';
import { SourceCode } from '../../../../../models/device-type.models';
import { Downlink } from '../../../../../models/device.models';
import { DevicesService } from '../../../../services/http/devices.service';
import { DataLoaderService } from '../../../../services/state/data/data-loader.service';

const evenLengthValidator = (
  control: AbstractControl,
): ValidationErrors | null => {
  const value = control.value;
  if (!value) return null;
  return value.length % 2 !== 0 ? { evenLength: true } : null;
};

@Component({
  selector: 'app-send-downlink-dialog',
  templateUrl: './send-downlink-dialog.component.html',
  styleUrls: ['./send-downlink-dialog.component.scss'],
})
export class SendDownlinkDialogComponent {
  downlinkForm = new FormGroup({
    payloadHex: new FormControl('', [
      Validators.required,
      Validators.pattern(/[0-9A-Fa-f]+$/),
      evenLengthValidator,
    ]),
    targetPorts: new FormControl('', [
      Validators.required,
      Validators.pattern(
        /^([1-9]|[1-9][0-9]|1[0-9][0-9]|2[0-1][0-9]|22[0-3])$/,
      ),
    ]),
  });
  downlinkIotCoreControl = new UntypedFormControl('', [
    Validators.required,
    isJson,
  ]);

  isHexaDecimalPayload = true;
  isJsonPayload = false;

  constructor(
    public dialogRef: MatDialogRef<SendDownlinkDialogComponent>,
    private deviceHttp: DevicesService,
    private dataLoaderService: DataLoaderService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public data:
      | { deviceIds: string[] }
      | { deviceId: string; sourceId: SourceCode; downlink?: Downlink },
  ) {
    if ('sourceId' in this.data) {
      this.isHexaDecimalPayload = this.data.sourceId !== 'iot_core';
      this.isJsonPayload = this.data.sourceId === 'iot_core';
    }
    if ('downlink' in this.data) {
      const { payload, port } = this.data.downlink!;
      if (this.isHexaDecimalPayload) {
        this.downlinkForm.setValue({ payloadHex: payload, targetPorts: port });
      }
      if (this.isJsonPayload) {
        this.downlinkIotCoreControl.setValue(payload);
      }
    }
  }

  public onSend() {
    let successMsg = 'Downlink sent!';
    let request = new Observable<string>();

    if ('deviceIds' in this.data) {
      successMsg = 'Downlink configuration successfully sent to devices';
      request = this.deviceHttp.sendMultiDownlink(
        this.data.deviceIds,
        this.downlinkForm.value,
      );
    }
    if ('deviceId' in this.data) {
      const { deviceId, sourceId } = this.data;
      request = this.deviceHttp
        .sendDownlink(
          deviceId,
          sourceId === 'iot_core'
            ? { json: this.downlinkIotCoreControl.value }
            : this.downlinkForm.value,
        )
        .pipe(
          mergeMap((downlinkSentResponse) =>
            this.dataLoaderService
              .loadDeviceDownlinks(deviceId)
              .pipe(map(() => downlinkSentResponse)),
          ),
        );
    }

    request.subscribe((downlinkSentResponse) => {
      if (downlinkSentResponse) {
        this.snackBar.open(successMsg, 'close');
        this.downlinkForm.reset();
      }
      this.dialogRef.close(downlinkSentResponse);
    });
  }

  isSendButtonDisabled() {
    if (this.isJsonPayload) return this.downlinkIotCoreControl.invalid;
    return this.downlinkForm.invalid;
  }
}

function isJson(control: AbstractControl) {
  try {
    JSON.parse(control.value);
    return null;
  } catch (e) {
    return { json: true };
  }
}
