import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Country } from 'src/models/countries.models';
import { Interface, RouteDto } from 'src/models/data-routing.models';
import { Brand, DeviceTypeDto, Source } from 'src/models/device-type.models';
import {
  DeviceDto,
  DeviceMonitoringStats,
  Downlink,
  SiteTag,
} from 'src/models/device.models';
import {
  Gateway,
  GatewayStats,
  RssiHistoryData,
} from 'src/models/gateway.models';
import { Organization } from 'src/models/organization.models';
import { User } from 'src/models/user.models';
import { InterfaceStateService } from './../interface/interface-store.service';

@Injectable({
  providedIn: 'root',
})
export class DataStoreService {
  private readonly _brands$ = new BehaviorSubject<undefined | Brand[]>(
    undefined,
  );

  private readonly _codecs$ = new BehaviorSubject<undefined | string[]>(
    undefined,
  );

  private readonly _countries$ = new BehaviorSubject<undefined | Country[]>(
    undefined,
  );

  private readonly _devices$ = new BehaviorSubject<undefined | DeviceDto[]>(
    undefined,
  );

  private readonly _allDevices$ = new BehaviorSubject<undefined | DeviceDto[]>(
    undefined,
  );

  private readonly _deviceMonitoring$ = new BehaviorSubject(
    {} as DeviceMonitoringStats,
  );

  private readonly _deviceTypes$ = new BehaviorSubject<
    undefined | DeviceTypeDto[]
  >(undefined);

  private readonly _gateways$ = new BehaviorSubject<undefined | Gateway[]>(
    undefined,
  );

  private readonly _gatewayRssi$ = new BehaviorSubject<{
    [key: string]: RssiHistoryData[];
  }>({});

  private readonly _interfaces$ = new BehaviorSubject<undefined | Interface[]>(
    undefined,
  );

  private readonly _organizations$ = new BehaviorSubject<
    undefined | Organization[]
  >(undefined);

  private readonly _routings$ = new BehaviorSubject<undefined | RouteDto[]>(
    undefined,
  );
  private readonly _routing$ = new BehaviorSubject<undefined | RouteDto>(
    undefined,
  );

  private readonly _site$ = new BehaviorSubject<undefined | SiteTag>(undefined);

  private readonly _sites$ = new BehaviorSubject<undefined | SiteTag[]>(
    undefined,
  );

  private readonly _sources$ = new BehaviorSubject<undefined | Source[]>(
    undefined,
  );

  private readonly _users$ = new BehaviorSubject<undefined | User[]>(undefined);

  private readonly _dataTags$: Observable<string[]>;

  private readonly _deviceDownlinks$ = new BehaviorSubject<
    undefined | Downlink[]
  >(undefined);

  constructor(private interfaceState: InterfaceStateService) {
    this._dataTags$ = this.deviceTypes$.pipe(
      map((types) => {
        const tags = new Set<string>();
        types?.forEach((type) => {
          type.metadata.functions?.forEach((tag) =>
            tags.add(tag.toLowerCase()),
          );
        });
        return Array.from(tags).sort((a, b) => a.localeCompare(b));
      }),
    );
  }

  public resetStore(): void {
    this._brands$.next(undefined);
    this._codecs$.next(undefined);
    this._countries$.next(undefined);
    this._devices$.next(undefined);
    this._allDevices$.next(undefined);
    this._deviceMonitoring$.next({} as DeviceMonitoringStats);
    this._deviceTypes$.next(undefined);
    this._gateways$.next(undefined);
    this._gatewayRssi$.next({});
    this._interfaces$.next(undefined);
    this._organizations$.next(undefined);
    this._routings$.next(undefined);
    this._routing$.next(undefined);
    this._sites$.next(undefined);
    this._sources$.next(undefined);
    this._users$.next(undefined);
    this._deviceDownlinks$.next(undefined);
  }

  //---------------------------------------------------------------------------
  // Brands
  //---------------------------------------------------------------------------

  public get brands(): Brand[] | undefined {
    return this._brands$.getValue();
  }

  public get brands$(): Observable<Brand[] | undefined> {
    return this._brands$.asObservable();
  }

  public setBrands(brands: Brand[]): void {
    this._brands$.next(brands.sort(this._sortBrands));
  }

  public addBrand(brand: Brand): void {
    const currentValue = this._brands$.getValue() as Brand[];
    this._brands$.next([...currentValue, brand].sort(this._sortBrands));
  }

  public updateBrand(brandUpdate: Brand): void {
    const currentValue = this._brands$.getValue() as Brand[];
    const updated = currentValue.map((brand) =>
      brand.name === brandUpdate.name ? brandUpdate : brand,
    );
    this._brands$.next(updated);
  }

  public deleteBrand(brandId: string) {
    const brands = this._brands$.value?.filter((brand) => brand.id !== brandId);
    this._brands$.next(brands);
  }

  private _sortBrands = (a: Brand, b: Brand) => a.name.localeCompare(b.name);

  //---------------------------------------------------------------------------
  // Codecs
  //---------------------------------------------------------------------------

  public get codecs(): string[] | undefined {
    return this._codecs$.getValue();
  }

  public get codecs$(): Observable<string[] | undefined> {
    return this._codecs$.asObservable();
  }

  public setCodecs(devices: string[]): void {
    this._codecs$.next(devices);
  }

  //---------------------------------------------------------------------------
  // Countries
  //---------------------------------------------------------------------------

  public get countries(): Country[] | undefined {
    return this._countries$.value;
  }

  public get countries$(): Observable<Country[] | undefined> {
    return this._countries$.asObservable();
  }

  public setCountries(countries: Country[]) {
    this._countries$.next(countries);
  }

  //---------------------------------------------------------------------------
  // DataTags
  //---------------------------------------------------------------------------

  public get dataTags$(): Observable<string[]> {
    return this._dataTags$;
  }

  //---------------------------------------------------------------------------
  // Devices
  //---------------------------------------------------------------------------

  public get devices(): DeviceDto[] | undefined {
    return this._devices$.getValue();
  }

  public get devices$(): Observable<DeviceDto[] | undefined> {
    return this._devices$.asObservable();
  }

  public getDeviceByID(id: string): DeviceDto {
    return this._devices$.value?.find(
      (device) => device.device_id === id,
    ) as DeviceDto;
  }

  public setDevices(devices: DeviceDto[]): void {
    this._devices$.next(devices);
  }

  public addDevice(device: DeviceDto): void {
    const currentValue = this._devices$.getValue() as DeviceDto[];
    this._devices$.next([...currentValue, device]);
  }

  public deleteDevice(deviceId: string): void {
    const currentValue = this._devices$.getValue();
    this._devices$.next(
      currentValue
        ? currentValue.filter((device) => device.device_id !== deviceId)
        : currentValue,
    );
  }

  //---------------------------------------------------------------------------
  // All Devices
  //---------------------------------------------------------------------------

  public get allDevices(): DeviceDto[] | undefined {
    return this._allDevices$.getValue();
  }

  public setAllDevices(devices: DeviceDto[]): void {
    this._allDevices$.next(devices);
  }

  //---------------------------------------------------------------------------
  // DeviceMonitoringStats
  //---------------------------------------------------------------------------

  public get deviceMonitoring$(): Observable<DeviceMonitoringStats> {
    return this._deviceMonitoring$.asObservable();
  }

  public setDeviceMonitoring(stats: DeviceMonitoringStats): void {
    this._deviceMonitoring$.next(stats);
  }

  //---------------------------------------------------------------------------
  // DeviceTypes
  //---------------------------------------------------------------------------

  public get deviceTypes$(): Observable<DeviceTypeDto[] | undefined> {
    return this._deviceTypes$.asObservable();
  }

  public setDeviceTypes(deviceTypes: DeviceTypeDto[]): void {
    this._deviceTypes$.next(deviceTypes.sort(this.sortDeviceTypes));
  }

  public getDeviceTypeByID(id: string): DeviceTypeDto | undefined {
    return this._deviceTypes$.value?.find(
      (deviceType) => deviceType.device_type_id === id,
    );
  }

  public addDeviceType(deviceType: DeviceTypeDto): void {
    const currentValue = this._deviceTypes$.getValue() as DeviceTypeDto[];
    this._deviceTypes$.next(
      [...currentValue, deviceType].sort(this.sortDeviceTypes),
    );
  }

  public updateDeviceType(newType: DeviceTypeDto): void {
    const typesValue = this._deviceTypes$.getValue();
    if (typesValue) {
      const index = typesValue.findIndex(
        (model) => model.device_type_id === newType.device_type_id,
      );
      typesValue.splice(index, 1, newType);
    }

    this._deviceTypes$.next(typesValue);
  }

  public deleteDeviceType(deviceTypeId: string): void {
    const newValue = this._deviceTypes$.value?.filter(
      (type) => type.device_type_id !== deviceTypeId,
    );
    this._deviceTypes$.next(newValue);
  }

  /**
   * Return all device types grouped by source, then manufacturer
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get groupedDeviceTypes$(): Observable<any> {
    return this.deviceTypes$.pipe(
      map((types) => {
        const mapper = types?.reduce((acc, curr) => {
          if (!acc[curr.source.source_id])
            acc[curr.source.source_id] = {
              source: curr.source,
              brandsMap: {},
            };

          const source = acc[curr.source.source_id];

          if (!source.brandsMap[curr.brand.name])
            source.brandsMap[curr.brand.name] = {
              brand: curr.brand,
              models: [],
            };

          source.brandsMap[curr.brand.name].models.push(curr);

          return acc;
        }, {});

        const results: unknown[] = [];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        for (const value of Object.values<any>(mapper!)) {
          results.push({
            source: value.source,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            brands: Object.values<any>(value.brandsMap).map((item) => ({
              brand: item.brand,
              models: item.models,
            })),
          });
        }

        return results;
      }),
    );
  }

  public changeDeviceTypeActiveState(id: string, state: boolean) {
    const types = this._deviceTypes$.value;
    if (types) {
      const indexToUpdate = types.findIndex(
        (type) => type.device_type_id === id,
      );
      types[indexToUpdate].disabled = state;
    }

    this._deviceTypes$.next(types);
  }

  private sortDeviceTypes = (a: DeviceTypeDto, b: DeviceTypeDto) =>
    a.metadata.label.localeCompare(b.metadata.label);

  //---------------------------------------------------------------------------
  // Gateways
  //---------------------------------------------------------------------------

  public get gateways$(): Observable<undefined | Gateway[]> {
    return this._gateways$.asObservable();
  }

  public setGateways(gateways: Gateway[]): void {
    this._gateways$.next(gateways);
  }

  public get gatewayStatistics$(): Observable<GatewayStats> {
    return combineLatest([
      this._gateways$,
      this.interfaceState.dashboardFilter$,
    ]).pipe(
      switchMap(([gw, filter]) =>
        filter.value.project_id.length
          ? of(
              gw
                ? gw.filter((gw) => gw.tags.includes(filter.value.project_id))
                : [],
            )
          : of(gw ? gw : []),
      ),
      map((gateways) => {
        const allowedTimeout = 14400000; // 4hours in milliseconds
        const active = gateways.filter(
          (gw) => Date.now() - Date.parse(gw.last_checkin) < allowedTimeout,
        ).length;

        return {
          active: active,
          inactive: gateways.length - active,
        };
      }),
    );
  }

  //---------------------------------------------------------------------------
  // GatewayRssi
  //---------------------------------------------------------------------------

  public get gatewayRssi(): {
    [key: string]: RssiHistoryData[];
  } {
    return this._gatewayRssi$.value;
  }

  public setGatewayRssi(gatewayId: string, rssiData: RssiHistoryData[]) {
    const value = this._gatewayRssi$.value;
    value[gatewayId] = rssiData;
    this._gatewayRssi$.next(value);
  }

  //---------------------------------------------------------------------------
  // Interfaces
  //---------------------------------------------------------------------------

  public get interfaces$(): Observable<Interface[] | undefined> {
    return this._interfaces$.asObservable();
  }

  public setInterfaces(interfaces: Interface[]): void {
    this._interfaces$.next(interfaces);
  }

  public addInterface(interf: Interface): void {
    const interfaces = [...(this._interfaces$.value as Interface[]), interf];
    this._interfaces$.next(interfaces);
  }

  public updateInterface(interfaceUpdate: Interface): void {
    let interfaces = this._interfaces$.value;
    if (interfaces) {
      interfaces = interfaces.map((interf) =>
        interf.interface_id === interfaceUpdate.interface_id
          ? interfaceUpdate
          : interf,
      );
    }
    this._interfaces$.next(interfaces);
  }

  //---------------------------------------------------------------------------
  // Organizations
  //---------------------------------------------------------------------------

  public get organizations$(): Observable<Organization[] | undefined> {
    return this._organizations$.asObservable();
  }

  public setOrganizations(organizations: Organization[]) {
    this._organizations$.next(organizations);
  }

  //---------------------------------------------------------------------------
  // Routings
  //---------------------------------------------------------------------------

  public get routings(): RouteDto[] | undefined {
    return this._routings$.value;
  }

  public get routings$(): Observable<RouteDto[] | undefined> {
    return this._routings$.asObservable();
  }

  public setRoutings(routings: RouteDto[]): void {
    this._routings$.next(routings);
  }

  public addRouting(routing: RouteDto): void {
    const routes = [...(this._routings$.value as RouteDto[]), routing];
    this._routings$.next(routes);
  }

  public get routing(): RouteDto | undefined {
    return this._routing$.value;
  }

  public get routing$(): Observable<RouteDto | undefined> {
    return this._routing$.asObservable();
  }

  public setRouting(routing: RouteDto): void {
    this._routing$.next(routing);
  }

  public resetRouting(): void {
    this._routing$.next(undefined);
  }

  public updateRoute(id: string, routeUpdate: RouteDto): void {
    let routes = this._routings$.value;
    if (routes) {
      routes = routes.map((route) => (route.id === id ? routeUpdate : route));
    }
    this._routings$.next(routes);
    this._routing$.next(routeUpdate);
  }

  public deleteRoute(id: string): void {
    const routes = this._routings$.value?.filter((route) => route.id !== id);
    this._routings$.next(routes);
  }

  public changeRouteActiveState(id: string, state: boolean): void {
    const routes = this._routings$.value;
    if (routes) {
      const indexToUpdate = routes.findIndex((route) => route.id === id);
      routes[indexToUpdate].is_active = state;
    }

    this._routings$.next(routes);
  }

  //---------------------------------------------------------------------------
  // Sites
  //---------------------------------------------------------------------------

  public get sites(): SiteTag[] | undefined {
    return this._sites$.value;
  }

  public get sites$(): Observable<SiteTag[] | undefined> {
    return this._sites$.asObservable();
  }

  public setSites(sites: SiteTag[]): SiteTag[] {
    sites.sort((a, b) => this.sortSites(a, b));
    this._sites$.next(sites);
    return sites;
  }

  public findSite(tagId: string): Observable<SiteTag | undefined> {
    const sites = this._sites$.getValue();
    if (sites) {
      const index = sites.findIndex((site) => site.tag_id === tagId);
      this._site$.next(sites[index]);
    } else {
      this._site$.next(undefined);
    }

    return this._site$;
  }

  public addSite(site: SiteTag) {
    const newSites = [...this._sites$.getValue()!, site];
    newSites.sort((a, b) => this.sortSites(a, b));
    this._sites$.next(newSites);
  }

  public updateSite(siteUpdate: SiteTag) {
    if (this._sites$.value) {
      const newSites = this._sites$.value.map((site) =>
        site.tag_id === siteUpdate.tag_id ? siteUpdate : site,
      );
      newSites.sort((a, b) => this.sortSites(a, b));
      this._sites$.next(newSites);
    }
  }

  private sortSites(a: SiteTag, b: SiteTag): number {
    return a.metadata.iso_code.localeCompare(b.metadata.iso_code) !== 0
      ? a.metadata.iso_code.localeCompare(b.metadata.iso_code)
      : a.metadata.site_long_name.localeCompare(b.metadata.site_long_name);
  }

  //---------------------------------------------------------------------------
  // Sources
  //---------------------------------------------------------------------------

  public get sources$(): Observable<Source[] | undefined> {
    return this._sources$.asObservable();
  }

  public get sources(): Source[] | undefined {
    return this._sources$.value;
  }

  public setSources(sources: Source[]): void {
    this._sources$.next(sources.sort(this._sortSources));
  }

  private _sortSources = (a: Source, b: Source) =>
    a.source_id.localeCompare(b.source_id);

  //---------------------------------------------------------------------------
  // Users
  //---------------------------------------------------------------------------

  public get users(): User[] {
    return this._users$.value ? this._users$.value : [];
  }

  public get users$(): Observable<User[] | undefined> {
    return this._users$.asObservable();
  }

  public setUsers(users: User[]): void {
    this._users$.next(users);
  }

  public addUser(user: User): void {
    const users = [...(this._users$.value as User[]), user];
    this._users$.next(users);
  }

  public updateUser(user: User): void {
    const users = this._users$.value;

    if (users) {
      const index = users.findIndex((u) => u.user_email === user.user_email);
      users.splice(index, 1, user);
    }

    this._users$.next(users);
  }

  public deleteUser(email: string) {
    const users = this._users$.value?.filter(
      (user) => user.user_email !== email,
    );
    this._users$.next(users);
  }

  //---------------------------------------------------------------------------
  // Downlinks
  //---------------------------------------------------------------------------

  public get deviceDownlinks$(): Observable<Downlink[] | undefined> {
    return this._deviceDownlinks$.asObservable();
  }

  public setDeviceDownlinks(downlink: Downlink[]): void {
    this._deviceDownlinks$.next(downlink);
  }

  //---------------------------------------------------------------------------
  // Others
  //---------------------------------------------------------------------------

  public get deviceClasses() {
    return deviceClasses;
  }

  public get regions() {
    return regions;
  }

  public dataRates(region?: string) {
    return region && (region === 'US915' || region === 'AU915')
      ? usDataRates
      : dataRates;
  }
}

const deviceClasses = [
  'ADRF/SensorsA.1_EU',
  'ADRF/FielTestDeviceA.1_EU',
  'ADRF/FielTestDeviceC.1_EU',
  'LORA/GenericC.1_ETSI_Rx2-SF12',
  'SMTC/LoRaMoteA.1_EU',
  'LORA/GenericA.1.0.2b_ETSI_Rx2-SF12',
  'LORA/GenericA.1.0.2a_ETSI_Rx2-SF12',
  'SMTC/LoRaMoteC.1_EU',
  'LORA/GenericC.1.0.2a_ETSI_Rx2-SF12',
  'LORA/GenericB.1.0.2b_ETSI_Rx2-SF12',
  'LORA/GenericA.1_ETSI_Rx2-SF9',
  'LORA/GenericC.1_ETSI_Rx2-SF9',
  'LORA/GenericB.1.0.2a_ETSI_Rx2-SF12',
  'LORA/GenericA.1_ETSI_Rx2-SF12',
  'LORA/GenericC.1.0.2b_ETSI_Rx2-SF12',
];

const regions = [
  { option: 'EU 868', value: 'EU868' },
  { option: 'US 915', value: 'US915' },
  { option: 'AU 915', value: 'AU915' },
  { option: 'IN 865', value: 'IN865' },
  { option: 'AS 923', value: 'AS923' },
  { option: 'CN 470', value: 'CN470' },
];

const dataRates = [
  { option: 'SF 12 / BW 125 kHz', value: 0 },
  { option: 'SF 11 / BW 125 kHz', value: 1 },
  { option: 'SF 10 / BW 125 kHz', value: 2 },
  { option: 'SF 9 / BW 125 kHz', value: 3 },
  { option: 'SF 8 / BW 125 kHz', value: 4 },
  { option: 'SF 7 / BW 125 kHz', value: 5 },
];

const usDataRates = [
  { option: 'SF 12 / BW 500 kHz', value: 8 },
  { option: 'SF 11 / BW 500 kHz', value: 9 },
  { option: 'SF 10 / BW 500 kHz', value: 10 },
  { option: 'SF 9 / BW 500 kHz', value: 11 },
  { option: 'SF 8 / BW 500 kHz', value: 12 },
  { option: 'SF 7 / BW 500 kHz', value: 13 },
];
