<div *ngIf="showSiteSelectionPrompt; else selectionPromptTmpl" class="main">
  <div class="global-conf-box light-blue-bg">
    <ng-container *ngTemplateOutlet="siteFormCtrlTmpl"></ng-container>
    <mat-slide-toggle
      class="global-slide"
      color="primary"
      [checked]="site.alertEnabled"
      [disabled]="!userHasUpdateRight"
      (change)="toggleSiteAlert()"
      >Alerts for site {{ siteSelection.value.split('_')[1] }}</mat-slide-toggle
    >
  </div>

  <mat-expansion-panel
    class="panel"
    [expanded]="alerting.userOpen"
    (closed)="setUserPanelOpenState(false)"
    (opened)="setUserPanelOpenState(true)"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Users </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="panel-body">
      <div class="panel-content">
        <div class="filter-box">
          <mat-form-field class="filter-field">
            <mat-label>Search e-mail</mat-label>
            <input matInput [formControl]="userEmailSearch" />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>
        <div class="list-header">
          <div class="list-row">
            <div class="list-col">Email</div>
            <div class="list-col">Alerts</div>
          </div>
        </div>
        <div class="list-body">
          <div class="list-row light-blue-bg pad-15" *ngFor="let user of users">
            <div class="list-col">{{ user.user_email }}</div>
            <div class="list-col">
              <mat-slide-toggle
                color="primary"
                [checked]="userAlertForSite(user)"
                [disabled]="!userHasUpdateRight"
                (change)="toggleUserAlert(user)"
              ></mat-slide-toggle>
            </div>
          </div>
        </div>
        <p *ngIf="!users.length" class="center">No results for your search!</p>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel
    class="panel"
    [expanded]="alerting.devicesOpen"
    (closed)="setDevicePanelOpenState(false)"
    (opened)="setDevicePanelOpenState(true)"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Devices </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="panel-body">
      <div class="panel-content">
        <ng-container *ngTemplateOutlet="deviceFilterTmpl"></ng-container>
        <div class="list-header">
          <div class="list-row">
            <div class="list-col">Name</div>
            <div class="list-col">ID</div>
            <div class="list-col">Battery Alerts</div>
            <div class="list-col">Connection Alerts</div>
            <div class="list-col">Connection Alert Delay</div>
          </div>
        </div>
        <div class="list-body">
          <div class="list-row light-blue-bg" *ngFor="let device of devices">
            <div class="list-col">{{ device.metadata?.name }}</div>
            <div class="list-col">{{ device.device_id }}</div>
            <div class="list-col">
              <mat-slide-toggle
                color="primary"
                [checked]="device.alarm.battery.enable"
                [disabled]="!userHasUpdateRight"
                (change)="toggleBatteryAlert(device)"
              ></mat-slide-toggle>
            </div>
            <div class="list-col">
              <mat-slide-toggle
                color="primary"
                [checked]="device.alarm.connection.enable"
                [disabled]="!userHasUpdateRight"
                (change)="toggleConnectionAlert(device)"
              ></mat-slide-toggle>
            </div>
            <div class="list-col">
              <mat-form-field class="delay-input">
                <input
                  matInput
                  type="number"
                  [value]="device.alarm.connection.delay"
                  [disabled]="!userHasUpdateRight"
                  (change)="updateConnectionAlertDelay($event, device)"
                />
              </mat-form-field>
              <p>h</p>
            </div>
          </div>
        </div>
        <p *ngIf="!devices.length" class="center">
          No results for your search!
        </p>
      </div>
    </div>
  </mat-expansion-panel>
</div>

<ng-template #selectionPromptTmpl>
  <div class="prompt-box">
    <h3>Please select a site to continue!</h3>
    <ng-container *ngTemplateOutlet="siteFormCtrlTmpl"></ng-container>
  </div>
</ng-template>

<ng-template #siteFormCtrlTmpl>
  <mat-form-field>
    <mat-label>Site</mat-label>
    <mat-select [formControl]="siteSelection">
      <mat-option *ngFor="let site of sites$ | async" [value]="site.code">
        {{ site.country.isoCode }} -
        {{ site.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #deviceFilterTmpl>
  <form
    [formGroup]="deviceFilterForm"
    *ngIf="deviceFilterForm && alerting.devicesOpen"
    class="filter-box"
  >
    <mat-form-field class="filter-select">
      <mat-label>Brand</mat-label>
      <mat-select formControlName="brand_name">
        <mat-option [value]="0">All</mat-option>
        <mat-option *ngFor="let brand of brands$ | async" [value]="brand.name">
          {{ brand.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="filter-select">
      <mat-label>Model</mat-label>
      <mat-select formControlName="device_type_id">
        <mat-option [value]="0">All</mat-option>
        <mat-option
          *ngFor="let model of models$ | async"
          [value]="model?.device_type_id"
          >{{ model?.metadata?.label }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field class="filter-select">
      <mat-label>Source Platform</mat-label>
      <mat-select formControlName="source">
        <mat-option [value]="0">All</mat-option>
        <mat-option
          *ngFor="let source of sources$ | async"
          [value]="source.code"
          >{{ source.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </form>
</ng-template>
