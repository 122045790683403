import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/state/auth/auth.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { environment } from 'src/environments/environment';
import { DeviceTypeDto } from 'src/models/device-type.models';
import { IotcoreLogLevel, PublicKeyFormat } from 'src/models/device.models';
import { Site } from 'src/models/site.models';
import { DeviceIdValidator } from '../../device-exists.validator';
import { DeviceManagementService } from '../../device-management.service';
import { DeviceCreation } from '../abstract-device-creation';
import { iotcoreCreationSteps } from '../device-creation.config';
import { DeviceCreationService } from '../device-creation.service';

@Component({
  selector: 'app-iotcore-device-creation',
  templateUrl: './iotcore-device-creation.component.html',
  styleUrls: ['./iotcore-device-creation.component.scss'],
})
export class IotCoreDeviceCreationComponent
  extends DeviceCreation
  implements OnInit
{
  // interface
  activeStepConfig$ = this.deviceCreation.activeStep$;
  activeStepIndex: number;
  loading = false;

  // interface data
  deviceClasses = this.data.deviceClasses;
  deviceType = this.deviceCreation.selectedDeviceType as DeviceTypeDto;
  site$: Observable<Site | undefined>;
  userAccessFlags = this.authService.claims?.accessFlags || [];

  // data collection
  form = this.buildForm();
  fileUploadForm = new UntypedFormControl(null, Validators.required);
  certificate$ = this.fileUploadForm.valueChanges.pipe(shareReplay(1));
  deviceControlGroups = new Set<string>();

  //iot core device config
  deviceConfig: { [key: string]: string } = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private data: DataStoreService,
    private deviceCreation: DeviceCreationService,
    public deviceManagement: DeviceManagementService,
    public authService: AuthService,
  ) {
    super();
    this.deviceConfig.project = `prj-iothub-${environment.stage}`;
    this.deviceConfig.region = `europe-west1`;
    this.deviceConfig.registry = `iot-ew1-iothub-${environment.stage}-core`;
  }

  ngOnInit(): void {
    this.stepQueryParamsHandler$.subscribe();
    this.pathQueryParamHandler$.subscribe();
  }

  private get stepQueryParamsHandler$(): Observable<number> {
    return this.route.queryParams.pipe(
      map((params) => parseInt(params.step, 10)),
      tap((step) => (this.activeStepIndex = step)),
      tap((step) =>
        this.deviceCreation.setActiveStep(step, iotcoreCreationSteps),
      ),
      filter((step) => !this.stepValidator(step - 1)),
      tap(() =>
        this.router.navigate([], {
          queryParams: { step: 1 },
          queryParamsHandling: 'merge',
        }),
      ),
    );
  }

  private get pathQueryParamHandler$(): Observable<string> {
    return this.route.queryParams.pipe(
      map((params) => params.path ?? ''),
      tap((path) => {
        this.deviceManagement.setPath(path);
        this.site$ = this.data.findSite(this.deviceManagement.activeProject);
      }),
    );
  }

  private updateConfigInfo(value: string) {
    switch (value) {
      case 'EU':
        this.deviceConfig.region = `europe-west1`;
        this.deviceConfig.registry = `iot-ew1-iothub-${environment.stage}-core`;
        break;
      case 'US':
        this.deviceConfig.region = `us-central1`;
        this.deviceConfig.registry = `iot-us1-iothub-${environment.stage}-core`;
        break;
      case 'ASIA':
        this.deviceConfig.region = `asia-east1`;
        this.deviceConfig.registry = `iot-ae1-iothub-${environment.stage}-core`;
        break;
      default:
        break;
    }
  }

  fileInputChange(fileInputEvent: Event) {
    const target = fileInputEvent.target as HTMLInputElement;
    const file = target.files![0];
    const fileReader = new FileReader();
    this.fileUploadForm.setValue(file);

    if (
      file &&
      (file.type.includes('x-x509-ca-cert') || file.type.includes('pkix-cert'))
    ) {
      fileReader.onload = () => {
        const res = fileReader.result as string;
        if (
          res.includes('-----BEGIN CERTIFICATE-----') &&
          res.includes('-----END CERTIFICATE-----')
        ) {
          this.technicalForm.get('key')?.setValue(res);
        } else {
          this.fileUploadForm.setErrors({ certFormat: true });
          this.technicalForm.get('key')?.setValue(null);
        }
      };

      fileReader.readAsText(file);
    } else {
      this.fileUploadForm.setErrors({ certFormat: true });
      this.technicalForm.get('key')?.setValue(null);
    }
  }

  public stepValidator(step = this.activeStepIndex): boolean {
    const general = this.generalForm.valid;
    const technical = this.technicalForm.valid;
    const position = this.positionForm.valid;
    const metadata = this.metadataForm.valid;

    switch (step) {
      case 1:
        return general;
      case 2:
        return technical && general;
      case 3:
        return position && technical && general;
      default:
        return metadata && position && technical && general;
    }
  }

  public submitForm() {
    const source = this.deviceType?.source.code;
    const deviceInfo = {
      ...this.generalForm.value,
      logLevel: this.technicalForm.controls.logLevel.value,
      blocked: this.technicalForm.controls.blocked.value,
      credentials: {
        key: this.technicalForm.controls.key.value,
        format: this.technicalForm.controls.format.value,
      },
      attributeTags: Array.from(this.deviceControlGroups),
      projectIds: [this.deviceManagement.activeProject],
      metadata: {
        ...this.positionForm.value,
        ...this.metadataForm.value,
        description: this.descriptionFormControl.value,
      },
    };
    this.deviceCreation.createDevice(deviceInfo, source);
  }

  public onFlagChange(checked: boolean, flag: string): void {
    checked
      ? this.deviceControlGroups.add(flag)
      : this.deviceControlGroups.delete(flag);
  }

  private buildForm(): UntypedFormGroup {
    const form = new UntypedFormGroup({
      general: new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        deviceRegion: new UntypedFormControl('EU', Validators.required),
        hierarchyPrefix: new UntypedFormControl(
          this.deviceManagement.hierarchyPrefix,
          Validators.required,
        ),
        deviceTypeId: new UntypedFormControl(
          this.deviceType?.device_type_id,
          Validators.required,
        ),
        id: new UntypedFormControl(
          '',
          [
            Validators.required,
            Validators.pattern(/^[a-z][A-Za-z0-9+.%-_~]*[A-Za-z0-9]$/),
          ],
          [DeviceIdValidator.createValidator(this.deviceManagement)],
        ),
      }),
      technical: new UntypedFormGroup({
        logLevel: new UntypedFormControl(
          IotcoreLogLevel.INFO,
          Validators.required,
        ),
        blocked: new UntypedFormControl(false),
        format: new UntypedFormControl(
          PublicKeyFormat.RSA_X509_PEM,
          Validators.required,
        ),
        key: new UntypedFormControl(null, Validators.required),
      }),
      position: new UntypedFormGroup({
        lat: new UntypedFormControl(null, Validators.required),
        lng: new UntypedFormControl(null, Validators.required),
      }),
      metaData: new UntypedFormGroup({}),
      description: new UntypedFormControl(''),
    });
    return form;
  }
}
